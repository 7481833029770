import * as yup from "yup";
import i18n from 'i18next'

export const StudentSchema = yup.object({
        lastname :yup.string().required("lastname_val"),
        firstname :yup.string().required("firstname_val"),
        classe: yup.string().required("select_class"),
        phone:yup.string()
                 .required("tel_val")
                 .matches(/^[0-9]+$/, "tel_val")
                 .min(10, 'tel_val')
                 .max(13, 'tel_val'),
        tobepayed:yup.number().integer("amount_number").min(0).max(100000000000).typeError("amount_number"),
        payed:yup.number().integer("amount_number").min(0).max(100000000000).typeError("amount_number"),
        studentnote:yup.string().max(20000),
});
