import React, {useEffect, useState} from 'react';
import {Route, Redirect} from 'react-router-dom';
import axios from "axios";
import {Logout} from "./logout/logout"

export const ProtectedRoute = ({component : Component, ...rest}) =>{
    return (
        <Route 
            {...rest}
            render = {props => {
                    if (localStorage.getItem("isAuth")) { return <Component {...props} />}
                    else {return <Redirect to ={{ pathname : "/login",state : {from :props.location }}}/>}
             }}
        />)}