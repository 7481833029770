import React, {Suspense}  from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import RingLoader from "react-spinners/RingLoader";
import Loader from "./Pages/components/loaders/loader"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs:["fr","en","ar"],
    fallbackLng: "en",
    detection:{
        order: ['cookie', 'htmlTag', 'navigator',  'path','localStorage', 'subdomain', 'querystring'],
        caches:["cookie"]
    },
    backend:{
        loadPath:'/language/{{lng}}/translation.json',
    },

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const loadingMarkup = (
  <Loader/>
)

ReactDOM.render(
                <Suspense  fallback={loadingMarkup}>
                  <BrowserRouter>
                      <App/>
                  </BrowserRouter>
                </Suspense>,
                
                document.getElementById("root"))





