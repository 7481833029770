

import RingLoader from "react-spinners/RingLoader";
import Watch from "react-loader-spinner";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";


export default function Loader() {
    const {t} = useTranslation();
    return (
        <div style={{ textAlign:"center",
                      display:"flex",
                      justifySelf:"center",
                      alignSelf:"center",
                      width:"100%",
                      height:"30vh",
                      backgroundColor:"#e8eaf6" }}
                       >
            <div style={{ textAlign:"center",
                      justifyContent:"center",
                      alignItems:"center", width:"100%", display:"flex", }}>
                 
                
                <Grid >
                    <Grid item xs={12}>
                            <Watch
                                type = "Watch"
                                color="#607d8b"
                                height={100}
                                width={100} 
                            />
                    </Grid>
                    <Grid item xs={12} >
                        <Typography sx={{color:"#607d8b", fontSize:"bold"}} > {t("loading")} </Typography>
                    </Grid>
                </Grid>
                
                
                
            </div>
            
        </div>    
    )}