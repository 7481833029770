import React, {useState, useEffect} from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import axios from "axios";
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Studentpopup from "../popups/studentpopup";
import LotPopUP from "../popups/lotpopup";
import Layout from "../components/accountlayout"
import CollapsibleTable from "../components/tables/presencestable"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Qrpopup from "../popups/qrpopup"
import PaymentPopup from "../popups/paymentpopup"
import Loader from "../components/loaders/tableloader";
import HandleRightToLeftDirection from "../components/rtl/rtl";
import {useTranslation} from "react-i18next";

export default function PresencesDisplay() {
    const {t} = useTranslation();
    const [IsLoading, setIsLoading] = useState(false);
    const [students, setStudents] = useState([]);
    const [ButtonQRPopup, SetButtonQRpopup] = useState(false);
    const [studentTobeModfied, SetTobeMod] =useState("");
    const [studentMod, setStudentMod] = useState("");
    const [reload, setreload] = useState(1)
    const [presences, setPresences] = useState("");
    const [classes, setClasses] = useState("");
    const MapNfilter = async (students, presences,classes)=>{
        for (var i = 0; i < presences.length; i++){
                const filtered_students = students.filter(student => student.id ===presences[i].student_id)
                const  filtered_classes = classes.filter(classe => classe.id ===presences[i].class_id)
                if (filtered_students.length >0){
                    presences[i].firstname = filtered_students[0].firstname 
                    presences[i].lastname = filtered_students[0].lastname}
                else{
                    presences[i].firstname = ""
                    presences[i].lastname =""}
                if (filtered_classes.length >0){presences[i].classe = filtered_classes[0].name}
                else{ presences[i].classe = ""}  
        }
    return presences}
    useEffect ( async () => {
            setIsLoading(true)
            const config = {headers:{
                    bearer:localStorage.getItem("bearer"),
                }}  
            try {
                const studentresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/students", config );
                const classesResponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/classes", config ); 
                const presencesResponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/scanner", config );
                if (presencesResponse.data.message==="No presence recorded"){setPresences("")}
                else{setStudents(studentresponse.data);
                    setClasses(classesResponse.data);
                    MapNfilter(studentresponse.data,presencesResponse.data,classesResponse.data).then(values => setPresences(values))
                }} catch(err){}
            setIsLoading(false)
    },[reload]);
    useEffect ( async () => {
        if (studentTobeModfied){
            setStudentMod(students.filter(student => student.id === parseFloat(studentTobeModfied))[0])
        }
    },[studentTobeModfied])
return (
    <Layout >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        {IsLoading ? <Loader/> :
                        [!presences ? t("empty_database"): <> <CollapsibleTable  presences = {presences}
                                                                                products ={students}
                                                                                setTrigger={SetButtonQRpopup}
                                                                                SetTobeMod = {SetTobeMod}
                                                                                setStudentMod={setStudentMod} />
                                                            </>]}
                        </Paper>
                    </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
        </Container>
    </Layout>
  );
}
