import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Previousreturns from "./previousreturns"
import SalesChart from  "./saleschart"
import ClassesChart from "./classbarchart"
import ClassesPaymentsChart from "./classpaymentsareachart"
import ClassesPresencesChart from "./classpresenceareachart"
import Copyright from "../components/copyright"
import Layout from "../components/accountlayout"

export default function Dashboard() {
  return (
          <Layout >
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                  {/* Chart */}
                      <SalesChart/>
                  {/* Recent returns */}
                      <Previousreturns/>
                  {/* Recent orders */}
                  
                  {/* Classes Bar Chart */}
                    {<ClassesChart/>}
                  {/* Classes Bar Chart */}
                  {/* Classes Payments chart */}
                    <ClassesPaymentsChart/>
                  {/* Classes Payments chart */}
                  {/* Classes Presences chart */}
                    <ClassesPresencesChart/>
                  {/* Classes presences chart */}
                </Grid>
                <Copyright sx={{ pt: 4 }} />
              </Container>
          </Layout>
  );
}






    

    // Checking if bearer token exists and if yes if it is ok
    // useEffect(async ()=>{
    //     if (localStorage.getItem("isAuth"))
    //     { const config = {headers:{
    //           bearer:localStorage.getItem("bearer"),
    //       }}  
  
    //       axios.get("/tokenvalidation", config ).then(
    //       res=>{
    //         localStorage.setItem("isAuth",true);
    //         history.push("/Dashboard")          
    //       }
          
    //     ).catch(err=>{ localStorage.removeItem("bearer"); localStorage.setItem("isAuth",false)});
    //   }
    // },[])