import react from "react";
import axios from "axios"

export  const Logout = async (history) => {

    const config = {headers:{
        bearer:localStorage.getItem("bearer"),
    }}  
    axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/logout",config);
    localStorage.clear();
    history.push("login")

}