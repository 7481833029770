import React, {useState} from 'react';
import "./productpopup.css"
import Button from '@mui/material/Button';
import Box  from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CirclePicker  } from 'react-color';
import ClickAwayListener from '@mui/material/ClickAwayListener';


function ColorPickerpopup(props) {
    
    const handleChangeComplete =(color, event)=>{
        props.setTrigger(false)
        props.setColor(color.hex)}
    const handleClickAwawy = () =>{
        props.setTrigger(false)}
    return (props.trigger) ? ( 

        <div className="popup">
            <ClickAwayListener onClickAway ={handleClickAwawy}>
                <div className="popup-inner" >
                
                            <Box  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: "center",
                                    bgcolor: 'background.paper',
                                    borderRadius:5,
                                    }}>
                                <Grid  container  columns={{ sm:1, xs:1}}>
                                    <Grid sm = {1} xs ={1}  
                                        sx={{ border: 1,borderRadius: 16,mb:2,
                                            justifyContent: 'center',
                                            alignItems: "center" }}>       
                                        <Typography sx={{ mr:1,
                                            ml:2,mb:1,mt:1,
                                            justifyContent: 'center',
                                            alignItems: "center",
                                            }}
                                            fontSize={10}>Veuillez Choisir une couleur..
                                        </Typography>
                                    </Grid>
                                    <Grid sm = {1} xs ={1}>
                                        <CirclePicker width ="100%"
                                                    circleSize ={17} 
                                                    circleSpacing ={10}
                                                    onChangeComplete={ handleChangeComplete }
                                            />
                                    </Grid>
                                </Grid>
                            </Box>
                        
                        {props.children}
                </div>
            </ClickAwayListener>
        </div>


     ):""  ;
}

export default ColorPickerpopup;