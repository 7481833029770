

import RingLoader from "react-spinners/RingLoader";
import BallTriangle from "react-loader-spinner";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";


export default function Loader() {
    return (
        <div style={{ textAlign:"center",
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center",
                      width:"100%",
                      height:"100vh",
                      backgroundColor:"#607d8b" }} >
                <Grid >
                    <Grid item xs={12}>
                            <BallTriangle
                                type = "BallTriangle"
                                color="#cfd8dc"
                                height={100}
                                width={100} 
                            />
                    </Grid>
                    <Grid item xs={12} >

                    </Grid>
                </Grid>
        </div>    
    )
}