import React from 'react';
import "./productpopup.css"
import Box  from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';


function Qrpopup(props) {
    const handleClickAwawy = () =>{
        props.setTrigger(false)}
    return (props.trigger) ? ( 
        <div className="popup">
            <ClickAwayListener onClickAway ={handleClickAwawy}>
            <div className={localStorage.getItem("drawer")==="true"?"popup-inner-qr-drawer":"popup-inner-qr"}>
                    <Box  sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: "center",
                                        bgcolor: 'background.paper',
                                        borderRadius:5,
                                        }}>
                        <a href={props.QRdownload} download="QR code">
                            <img src = {props.QRdownload} />
                        </a>
                    </Box>
                    {props.children}
                </div>
            </ClickAwayListener>
        </div>
    ):""  ;
}
export default Qrpopup;