import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {useFormik} from "formik";
import {forgotpasswordSchema} from "../../Validations/forgotpasswordval";
import {useState, useEffect} from "react";
import axios from "axios";
import {useHistory} from "react-router-dom"
import Copyright from "../../components/copyright"
import {useTranslation} from "react-i18next"


const theme = createTheme();

export default function ForgotPassword() {
    const {t} = useTranslation();
    let history = useHistory();
    // Checking if authenticated then redirect to Dashboard
  useEffect( ()=>{
    if (localStorage.getItem("isAuth"))
  
        history.push("/dashboard")          
    
    })
  // Checking if authenticated then redirect to Dashboard
  
  const [APIerror, setAPIERROR] = useState("")
  const [APIpositive, setAPIPOSITIVE] = useState("")
  const formik = useFormik({
          initialValues:{
            email:'',
          },
          onSubmit: async(values) =>{
            axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/forgotpassword", values ).then(
                  res=>{
                      setAPIPOSITIVE(res.data.message);              
                      console.log(res)
                  }
                ).catch(
                  err=>{  
                    if (err.response.status ===401){
                    setAPIERROR(err.response.data.message)};
                    })
          },
          validationSchema:forgotpasswordSchema
  });
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <VisibilityTwoToneIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("forgot_password")}
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
            
            
          {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>} 
          
          {APIpositive.length  === 0 ? ""  : <Alert severity="success"> {APIpositive}</Alert>}   
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("email")}
              name="email"
              autoComplete="email"
              autoFocus
              value = {formik.values.email}
              onChange = { formik.handleChange  }
              error = {formik.touched.email && formik.errors.email}
              helperText = {formik.touched.email && formik.errors.email}
            />
        
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("reinit_password")}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2">
                  {t("account_login")}
                </Link>
              </Grid>
              <Grid item>
                <Link href="signup" variant="body2">
                {t("account_signup")}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}