
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from "@material-ui/core/TextField"
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import {signupSchema} from "../../Validations/signupvalidation"
import {useFormik} from "formik";
import {useState,  useEffect} from "react";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next"
import HandleRightToLeftDirection from "../../components/rtl/rtl"
import ReCAPTCHA from "react-google-recaptcha";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props} >
      {'Copyright © '}
      <Link color="inherit" href="">
        SalaaKalaa
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();


export default function Signup() {
  const {t} = useTranslation();
  let history = useHistory(); 
  const [recaptchaverified, setRecaptchaVerified] = useState("")
  const [disableB, setDisableB] = useState(false)
  // Checking if authenticated then redirect to Dashboard
    useEffect( ()=>{
      if (localStorage.getItem("isAuth"))
          history.push("/dashboard")          
      })
  // Checking if authenticated then redirect to Dashboard
  const [APIerror, setAPIERROR] = useState("")
  const formik = useFormik({
          initialValues:{
            lastname:'',
            firstname:'',
            company:'',
            phone:'',
            email:'',
            password:'',
            cpassword:'',
          },
          onSubmit: async(values) =>{
            if (recaptchaverified !==""){
              setDisableB(true)
              const activationdata = {
                email:values.email
              }
              axios.post("http://127.0.0.1:5000/teacher", values ).then(
                    res=>{
                      axios.post("http://127.0.0.1:5000/getactivationlink",activationdata);
                      history.push("/login")               
                    }
                  ).catch(
                    err=>{ 
                        setDisableB(false); 
                        if (err.response.status ===401){
                        setAPIERROR(err.response.data.message)};
              })
            }else{setAPIERROR(t("veryfi_recaptcha"))}
          },
          validationSchema:signupSchema
  });
  function HandleRecaptchaChange(value) {
    setRecaptchaVerified(value);
  }
return (
      <Grid container component="main" sx={{ height: '100vh' }} >
      <HandleRightToLeftDirection>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={12}
          md={7}
          sx={{
            backgroundImage:  `url(${process.env.PUBLIC_URL + '/School.jpg'})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <AccountBoxIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("signup")}
            </Typography>
            <Box component="form" noValidate  onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            
            {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>} 
            <TextField
                margin="normal"
                
                fullWidth
                id="lastname"
                label={t("lastname")}
                name="lastname"
                autoComplete="Nom"
                autoFocus
                value = {formik.values.lastname}
                onChange = { formik.handleChange  }
                error = {formik.touched.lastname && !!formik.errors.lastname}
                helperText = {t(formik.touched.lastname && formik.errors.lastname)}
              />
              <TextField
                margin="normal"
                
                fullWidth
                id="firstname"
                label={t("firstname")}
                name="firstname"
                autoComplete="firstname"
                autoFocus
                onChange = { formik.handleChange  }
                value = {formik.values.firstname}
                error = {formik.touched.firstname && !!formik.errors.firstname}
                helperText = {t(formik.touched.firstname && formik.errors.firstname)}

              />
              <TextField
                margin="normal"
                
                fullWidth
                id="company"
                label={t("company")}
                name="company"
                autoComplete="company"
                autoFocus
                onChange = { formik.handleChange  }
                value = {formik.values.company}
                error = {formik.touched.company && !!formik.errors.company}
                helperText = {t(formik.touched.company && formik.errors.company)}
                
              />
               
              <TextField
                margin="normal"
                
                fullWidth
                id="phone"
                label={t("tel")}
                name="phone"
                autoComplete="tel"
                autoFocus
                value = {formik.values.phone}
                onChange = { formik.handleChange  }
                error = {formik.touched.phone && !!formik.errors.phone}
                helperText = {t(formik.touched.phone && formik.errors.phone)}

              />
              <TextField
                margin="normal"
                
                fullWidth
                id="email"
                label={t("email")}
                name="email"
                autoComplete="email"
                autoFocus
                onChange = { formik.handleChange  }
                value = {formik.values.email}
                error = {formik.touched.email && !!formik.errors.email}
                helperText = {t(formik.touched.email && formik.errors.email)}

              />
              <TextField
                margin="normal"
                
                fullWidth
                name="password"
                label={t("my_password")}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange = { formik.handleChange  }
                value = {formik.values.password}
                error = {formik.touched.password && !!formik.errors.password}
                helperText = {t(formik.touched.password && formik.errors.password)}

              />
              <TextField
                margin="normal"
                
                fullWidth
                name="cpassword"
                label={t("comfirm_password")}
                type="password"
                id="cpassword"
                autoComplete="current-password"
                onChange = { formik.handleChange  }
                value = {formik.values.cpassword}
                error = {formik.touched.cpassword && !!formik.errors.cpassword}
                helperText = {t(formik.touched.cpassword && formik.errors.cpassword)}

              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t("signup")}
              </Button>
              <ReCAPTCHA
                sitekey="6LdkAAYeAAAAAI_WpIyFcghb4viR7ZT15tBw-PU-"
                onChange={HandleRecaptchaChange}
                onExpired={HandleRecaptchaChange}
              />
              <Grid container>
                <Grid item xs>
                    <Link href="/login" variant="body2">
                         {t("account_login")}
                    </Link>
                </Grid>
                
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </HandleRightToLeftDirection>
      </Grid>
  );
}