import React, {useEffect, useState} from 'react'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import IconButton from '@mui/material/IconButton';
import { blueGrey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import FaceIcon from '@mui/icons-material/Face';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import {Logout} from "../../Auth/logout/logout"
import ReactCountryFlag from "react-country-flag"
import Cookies from 'universal-cookie'
import i18next from "i18next";
import Languages from "../../language/languages";
import {RtlOutput} from "../rtl/rtldropdown";

const AccountMenu = (props) => {
  const cookies = new Cookies();
  const currentLanguageCode =(cookies.get("i18next") || "en")
  const ChangeLangue =(variable)=>{
    i18next.changeLanguage(variable)
    document.body.dir = Languages.Languages.find(l => l.code === variable).dir || "ltr"
    props.setLang(Languages.Languages.find(l => l.code === variable).dir)
  }
  return (
    <div>
      {
          Languages.Languages.map(({code,name,country_code}) => 
          <ListItem key={code} button onClick={()=> ChangeLangue(code) } disabled = {code === currentLanguageCode}> 
              <ListItemIcon>  
                <ReactCountryFlag countryCode={country_code} svg />
              </ListItemIcon>
              <ListItemText primary={name} />
        </ListItem>)
      }
    </div>
  )};
export default function LanguageDropDownMenu(props) {
  let TxtDir = RtlOutput()
  //  get instant direction changes from dom 
    const styles = {
        position: 'absolute',
        top: 45,
        left: TxtDir==="ltr" ? "" : 15,
        right:TxtDir==="ltr" ? 15 : "",
        zIndex: 1,
        border: '1px solid',
        bgcolor: "#b0bec5",
        width:160,
        borderRadius:2,
      }; 
   return ( 
    <Box sx={styles} >
                    
        <AccountMenu setLang={props.setLang} /> 
            
    </Box>
    );
}