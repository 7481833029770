import React, {useState, useEffect, useRef} from 'react';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import TextField from "@material-ui/core/TextField"
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Layout from "../components/accountlayout"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@mui/material/Select';
import QrReader from "react-qr-reader";
import {useTranslation} from "react-i18next";
import HandleRightToLeftDirection from "../components/rtl/rtl";
import Loader from "../components/loaders/tableloader";
import matador from "./prelunch.svg"    
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { SocialIcon } from 'react-social-icons';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Posts = [
  {
    label: "Un tirage aura lieux le 15 Février et 58 participant pourons personnaliser le site a leurs besoins. Soyez les premiers et beneficier d'un abonnement gratuit a vie.",
    color:
      '#f3e5f5',
  },
  {
    label: "Premiere plateform pour les enseigant, pousser la gestion de vos classe et bénéficier d'un outil qui permettera l'analyse et l'augmentation de votre revenue.",
    color:
      '#eceff1',
  },
  {
    label: "Mettre a jour vos outils pour devenir l'enseignant 2.0",
    color:
      '#e0f2f1',
  },
  {
    label: "Soyez parmis les 58 premier qui bénéficirons de 30% du montant de l'abonnement pour chaque candidat qui s'inscrit en utilisant votre code",
    color:
      '#ede7f6',
  },
];

const Secondposts = [
    {
      label: "Notre équipe est la pour une meilleur experience pour vous et vos etudiants.",
      color:
      '#eceff1',
    },
    {
      label: "Meilleurs gestion de revenue et des classes",
      color:
        '#f3e5f5',
    },
    {
      label: "Automatiser votre precessus et gerer votre business comme les grandes entreprises",
      color:
        '#ede7f6',
    },
    {
      label: "Mettre a jours vos outils et bénéficier des outils les plus récents",
      color:
        '#e0f2f1',
    },
  ];
const Carousel = (props) =>{
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = props.data.length;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)}
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)}
    const handleStepChange = (step) => {
        setActiveStep(step)};
    return (
    <Box sx={{  flexGrow: 1}}>
        <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
        >
            {props.data.map((step, index) => (
            <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                <Box
                    sx={{
                    height: 200,
                    display: 'block',
                    overflow: 'hidden',
                    width: '100%',
                    backgroundColor:step.color,
                    }}
                    alt={step.label}
                >   
                    <div style={{display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "100%",}} >
                
                    <Typography style={{
                        textAlign:"center",
                    }}>
                        {step.label}
                    </Typography>
                
                </div>
                </Box>
                ) : null}
            </div>
            ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
            <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
            >
                Next
                {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
                ) : (
                <KeyboardArrowRight />
                )}
            </Button>
            }
            backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
                ) : (
                <KeyboardArrowLeft />
                )}
                Back
            </Button>
            }
        />
    </Box>)
}

const MainPost = () =>{
    return (
        <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${matador})`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={matador} />}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.3)',
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Typography component="h1" variant="h3" color="inherit" gutterBottom>
              TikTik
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              Foyer des enseignants algeriens 
            </Typography>
            <Link variant="subtitle1" href="https://forms.gle/Xdo5bFRXqbKc9Qim9">
              Participer pour un meilleur avenir
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Paper>
    )
}

const SocialMediaLinks = () =>{
    return (
        <>  
            <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                <SocialIcon url="https://facebook.com/jaketrent" style={{margin:15}}/>
                <SocialIcon url="https://instagram.com/jaketrent" style={{margin:15}}/>
                <SocialIcon url="https://youtube.com/jaketrent" style={{margin:15}}/>
            </Grid>
        </>
    )
}

const HeaderData = () =>{
    return (
        <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Button size="small" onClick={()=>window.location.assign("/prelunch")}>
            Matador Teaching
        </Button>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1 }}
        >
        </Typography>
        <Button variant="outlined" size="small" >
            <a href="mailto:sales@company.com?subject=Offer Inquiry&body=Hi i would like to participate in the prelunch process..." 
               style={{textDecoration: "none"}}>
                Contact us
            </a>
        </Button>
        
      </Toolbar>
    )}

export default function PreLunch() {
return (
    <>
        <Container maxWidth="lg">
            <HeaderData/>
            <Grid
            container
            spacing={4}
            >   
                <Grid item xs={12} sx={{mt:3}}  >
                    <MainPost/>    
                </Grid>
                <Grid item md={6} xs={12} >
                    <Carousel data ={Posts}/>    
                </Grid>
                <Grid item md={6} xs={12} >
                    <Carousel data ={Secondposts}/>    
                </Grid>
                <SocialMediaLinks/>    
            </Grid>
        </Container>
        <Copyright/>
        
    </>
  );
}
