class Auth  {
    login (cb){
        localStorage.setItem("isAuth",true);
    cb();
    }
    logout (cb) {

        localStorage.setItem("isAuth",false);
        localStorage.removeItem("bearer")
        cb();
    }
}
export default new Auth();
