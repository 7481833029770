import React, {useState} from 'react';
import "./productpopup.css"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid  from '@mui/material/Grid';
import Box  from '@mui/material/Box';
import Avatar  from '@mui/material/Avatar';
import Typography  from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import {LotSchema} from "../Validations/lotvalidation"
import {useFormik} from "formik";
import axios from "axios"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import moment from "moment"

function LotPopUP(props) {
     const [APIerror, setAPIERROR] = useState("")
     const config = {headers:{
          bearer:localStorage.getItem("bearer"),
      }}  
     const[pexpiry, SetPexpiry] = useState(true)
     const formik = useFormik({
          initialValues:{
            product_id:'',
            lot:'',
            buyprice:'',
            quantity:'',
            purchasedate:null,
            expirationdate:null,
          },
          onSubmit: async(values) =>{
            
            axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/lotdetails/" + values.product_id, values ,config).then(
                  res=>{
                                
                    window.location.reload(false)
                  }
                ).catch(
                  err=>{  
                    if (err.response.status ===401){
                    setAPIERROR(err.response.data.message)};
                    })
          },
          validationSchema:LotSchema
      });
    return (props.trigger) ? ( 

        <div className="popup">
            <div className="popup-inner" >
                    
            
                    
                    <Box component="form"   onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
                    {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>} 
                        <TextField
                              fullWidth
                              select
                              id="product_id"
                              name="product_id"
                              size="small"
                              sx={{ mt: 0.5, mb:0.5 }}
                              label="Produit"
                              value = {formik.values.product_id}
                              onChange = { formik.handleChange  }
                              error = {formik.touched.product_id && !!formik.errors.product_id}
                              helperText = {formik.touched.product_id && formik.errors.product_id}

                              >
                                  {props.product.map((product) => (
                                        <MenuItem key={product.id}  value={product.id}>{product.name}</MenuItem> 
                                        
                                      )
                                  )
                                  }
                         </TextField>
                         <TextField
                              margin="normal"
                              fullWidth
                              size="small"
                              sx={{ mt: 0.5, mb:0.5 }}
                              id="lot"
                              label="Lot"
                              name="lot"
                              autoComplete="lot"
                              autoFocus
                              value = {formik.values.lot}
                              onChange = { formik.handleChange  }
                              error = {formik.touched.lot && !!formik.errors.lot}
                              helperText = {formik.touched.lot && formik.errors.lot}
                              
                         />
                         <TextField
                              margin="normal"
                              fullWidth
                              size="small"
                              sx={{ mt: 0.5, mb:0.5 }}
                              name="buyprice"
                              label="Prix Achat"
                              id="buyprice"
                              autoComplete="buyprice"
                              value = {formik.values.buyprice}
                              onChange = { formik.handleChange  }
                              error = {formik.touched.buyprice && !!formik.errors.buyprice}
                              helperText = {formik.touched.buyprice && formik.errors.buyprice}
                         />
                        <TextField
                              margin="normal"
                              fullWidth
                              size="small"
                              sx={{ mt: 0.5, mb:0.5 }}
                              name="quantity"
                              label="Quantité"
                              id="quantity"
                              autoComplete="quantity"
                              value = {formik.values.quantity}
                              onChange = { formik.handleChange  }
                              error = {formik.touched.quantity && !!formik.errors.quantity}
                              helperText = {formik.touched.quantity && formik.errors.quantity}
                         />
                        <LocalizationProvider  dateAdapter={AdapterDateFns} >
                            <Stack spacing={3}  >
                                <DatePicker
                                    label="Date d'achat"
                                    value = {formik.values.purchasedate}
                                    onChange={(newValue) => {
                                    formik.setFieldValue("purchasedate",moment(newValue).format('YYYY-MM-D'));
                                    }}
                                    
                                    renderInput={(params) => <TextField sx={{mt:0.5,mb:0.5, }} size="small" {...params} />}
                                    error = {formik.touched.purchasedate && !!formik.errors.purchasedate}
                                    helperText = {formik.touched.purchasedate && formik.errors.purchasedate}
                                />
                              </Stack>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <Stack spacing={3}>
                              {/* Verifying if product has expiry date to display expiry date picker */}
                                {formik.values.product_id? props.product.filter(product => product.id === formik.values.product_id )[0].expiry?(
                                    <DatePicker
                                        label="Date d'expiration"
                                        value = {formik.values.expirationdate}
                                        onChange={(newValue) => {
                                        formik.setFieldValue("expirationdate",moment(newValue).format('YYYY-MM-D'));
                                        }}
                                        renderInput={(params) => <TextField sx={{mt:0.5,mb:0.5}} size="small" {...params} />}
                                        error = {formik.touched.expirationdate && !!formik.errors.expirationdate}
                                        helperText = {formik.touched.expirationdate && formik.errors.expirationdate}
                                   />
                                        ):"" :""}
                              {/* Verifying if product has expiry date to display expiry date picker */}
                            </Stack>
                        </LocalizationProvider>

                         <Button
                              type="submit"
                              fullWidth
                              size="small"
                              sx={{ mt: 0.5, mb: 0.5, bgcolor:"#81c784", color:"white",":hover":{bgcolor:"#43a047"} }}
                         >
                              Ajouter lot
                         </Button>
                         <Button
                              sx={{bgcolor:"#ef9a9a", mt:0.5,color:"white",":hover":{bgcolor:"#ef5350"} }}
                              fullWidth
                              size="small"
                              
                              onClick ={() => props.setTrigger(false)}
                         >
                              Fermer
                         </Button>
                    
                    </Box>
                    
                    {props.children}
            </div>
        </div>


     ):""  ;
}

export default LotPopUP;