import * as yup from "yup";


export const ModifyIntoSchema = yup.object({
        lastname :yup.string().required("your_lastname"),
        firstname:yup.string().required("your_firstname"),
        company:yup.string().required("your_company"),
        phone:yup.string()
                 .required("tel_val")
                 .matches(/^[0-9]+$/, "tel_val")
                 .min(10, 'tel_val')
                 .max(13, 'tel_val'),
        email:yup.string().email("email_input").required("email_input"),
    
});