import React, {useEffect, useState} from 'react';
import { useTheme } from '@mui/material/styles';
import {  XAxis, YAxis, Label, BarChart, ResponsiveContainer,Tooltip, Legend, Bar, CartesianGrid, LabelList, Cell} from 'recharts';
import Title from './Title';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from "axios"
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic'
import Loader from "../components/loaders/tableloader"
import { useTranslation } from "react-i18next";

const colors = Array(10).fill(scaleOrdinal(schemeCategory10).range()).flat() 
export default function ClassesChart() {
  const {t} = useTranslation();
  const [Students, setStudents] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [showyaxis, setYaxis] = useState(true);
  const theme = useTheme();
  useEffect ( async () => {
    setIsLoading(true);
    const config = {headers:{
            bearer:localStorage.getItem("bearer"),
        }}  
    try {
        const studentsresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/students", config );
        setStudents([...new Set(studentsresponse.data.map(item => item.classe))].map(classe =>  ({name:classe,  "Nombre d'étudiants": studentsresponse.data.filter( student => student.classe === classe).length})))
    } catch(err){}
    setIsLoading(false);
  },[]);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 800 && showyaxis) {
        setYaxis(false);
      } else if (window.innerWidth >= 800 && showyaxis === false) {
        setYaxis(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [showyaxis]);
 return (
    <React.Fragment>
       <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height:  !Students ? 100 : 240 ,
                }}
            >
                    <Title> {t("student_classe")}</Title>
                    {IsLoading ? <Loader/> :
                    !Students ? t("empty_database"): 
                        <ResponsiveContainer>
                            <BarChart width={730} height={250} data={Students} >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name"  angle="-7" style={{fontSize:8}}/>
                                {showyaxis && <YAxis style={{fontSize:10}} />}
                                <Tooltip />
                                <Bar dataKey= "Nombre d'étudiants" >
                                {
                                        Students.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={colors[index]} />
                                        ))
                                    }
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer> 
                    
                    }
            </Paper>
        </Grid>
      
    </React.Fragment>
  );
}
