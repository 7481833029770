import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {useFormik} from "formik";
import {resetpasswordSchema} from "../../Validations/resetpasswordval";
import {useState, useEffect} from "react";
import axios from "axios";
import {useHistory} from "react-router-dom" 
import Copyright from "../../components/copyright"



const theme = createTheme();

export default function ResetPassword() {
  let history = useHistory();
  // Checking if authenticated then redirect to Dashboard
useEffect( ()=>{
  if (localStorage.getItem("isAuth"))

      history.push("/dashboard")          
  
  })
// Checking if authenticated then redirect to Dashboard

  
  const [APIerror, setAPIERROR] = useState("")
  const [APIpositive, setAPIPOSITIVE] = useState("")
//   Check if link is expired
  axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/checkresetpassword", {"url":window.location.href} ).then().catch(
    err=>{  
      
      if (err.response.status ===401){
      setAPIERROR(err.response.data.message)};
      
      })
  //   Check if link is expired
  const formik = useFormik({
          initialValues:{
            password:'',
            cpassword:"",
            url:window.location.href,
          },
          onSubmit: async(values) =>{
            
            axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/resetpassword", values ).then(
                  res=>{
                    setAPIPOSITIVE(res.data.message);              
                      
                  }
                ).catch(
                  err=>{  
                    if (err.response.status ===401){
                    setAPIERROR(err.response.data.message)};
                    })
          },
          validationSchema:resetpasswordSchema
  });
    //   Show Input and button if link is not expired
  let show;
  if (APIerror.length  === 0) {
    show = "block"
  } else{
    show="none"
  }
  
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <VisibilityTwoToneIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Réinitialiser mon mot de passe
          </Typography>
          <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        
          {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>} 
          
          {APIpositive.length  === 0 ? ""  : <Alert severity="success"> {APIpositive}</Alert>}   
          
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Mot de passe"
              name="password"
              autoComplete="password"
              autoFocus
              value = {formik.values.password}
              onChange = { formik.handleChange  }
              error = {formik.touched.password && !!formik.errors.password}
              helperText = {formik.touched.password && formik.errors.password}
              sx={{ display: show }}
            />
                 <TextField
              margin="normal"
              required
              fullWidth
              id="cpassword"
              label="Confirmer mot de passe"
              name="cpassword"
              autoComplete="cpassword"
              autoFocus
              value = {formik.values.cpassword}
              onChange = { formik.handleChange  }
              error = {formik.touched.cpassword && !!formik.errors.cpassword}
              helperText = {formik.touched.cpassword && formik.errors.cpassword}
              sx={{ display: show }}
            />
        
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, display: show }}
            >
              Réinitialiser votre mot de passe
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2" >
                         {"Retourner vers la page Login? Login"}
                </Link>
              </Grid>
              <Grid item>
              
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}