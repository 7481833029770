import * as yup from "yup";


export const LotSchema = yup.object({
        product_id:yup.number().integer().required("Veuillez choisir un produit!!"),
        lot:yup.string().max(50),
        buyprice:yup.number().min(1).max(100000000000).typeError("Le prix d'achat doit etre un nombre"),
        quantity:yup.number().min(1).max(100000000000).typeError("La quantité doit etre un nombre").required("Vueillez saisir la quantité"),
        purchasedate:yup.date().nullable().default(null),
        expirationdate:yup.date().nullable().default(null),
    
});
