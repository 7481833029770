import React, {useState, useEffect, useRef} from 'react';
import {
    StylesProvider,
    jssPreset,
    ThemeProvider,
    createTheme
  } from "@material-ui/core/styles";
  import { create } from "jss";
  import rtl from "jss-rtl";
  import TextField from "@material-ui/core/TextField";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const ltrTheme = createTheme({ direction: "ltr" });
const rtlTheme = createTheme({ direction: "rtl" });

export default function HandleRightToLeftDirection (props) {
  const [TxtDir,setTxtDir] = useState(ltrTheme);
    //  get initial direction changes from dom
    React.useLayoutEffect(() => {
        document.body.getAttribute("dir") ==="ltr" ? setTxtDir(ltrTheme) : setTxtDir(rtlTheme)
      }, [TxtDir]);
    //  get instant direction changes from dom
     useEffect(() => {
        const observer = new MutationObserver((mutationsList, observer) => {
          if (mutationsList.some((mutation) => mutation.attributeName === "dir")) {
            document.body.dir ==="ltr" ? setTxtDir(ltrTheme) : setTxtDir(rtlTheme)
          }
        });
        observer.observe(document.body, {
          attributes: true
        });
        return () => observer.disconnect();
      }, []);
    //  get instant direction changes from dom 
    return (
        <>
            <StylesProvider jss={jss}> 
            <ThemeProvider theme={TxtDir}>
                 {props.children}
            </ThemeProvider>
            </StylesProvider>
        </>
    )} 