import React, {useState, useEffect, useRef} from 'react';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import TextField from "@material-ui/core/TextField"
import Paper from '@mui/material/Paper';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Layout from "../components/accountlayout"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@mui/material/Select';
import QrReader from "react-qr-reader";
import {useTranslation} from "react-i18next";
import HandleRightToLeftDirection from "../components/rtl/rtl";
import Loader from "../components/loaders/tableloader";
import matador from "./prelunch.svg"    
import Photo from "./me.jpg"    
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { SocialIcon } from 'react-social-icons';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Temoignage = [
  {
    name: "Kabour Lahcene",
    profession:"Prof de math",
    color:
      'white',
    photo:'/temoignage/me.jpg',
    temoignage:'In the Leon plug and remediation, it was learned that the simulation of water holdup and low methanol concentrations were important ingredients to determine both the cause and location hydrate plug formation.'
  },
  {
    name: "Didine",
    profession:"Prof de math",
    color:
      'white',
    photo:"/temoignage/spectrum.png",
    temoignage:'In the Leon plug and remediation, it was learned that the simulation of water holdup and low methanol concentrations were important ingredients to determine both the cause and location hydrate plug formation.'
  },
  {
    name: "Randa Amara",
    profession:"Prof de science",
    color:
      'white',
    photo:"/temoignage/me.jpg",
    temoignage:'In the Leon plug and remediation, it was learned that the simulation of water holdup and low methanol concentrations were important ingredients to determine both the cause and location hydrate plug formation.'
  },
  {
    name: "Ibtissem Delli",
    profession:"Prof d'histoire et geographie'",
    color:
      'white',
    photo:"/temoignage/spectrum.png",
    temoignage:'In the Leon plug and remediation, it was learned that the simulation of water holdup and low methanol concentrations were important ingredients to determine both the cause and location hydrate plug formation.'
  },
];

const Carousel = (props) =>{
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = props.data.length;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)}
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)}
    const handleStepChange = (step) => {
        setActiveStep(step)};
    return (
    <Box sx={{  flexGrow: 1}}>
        <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
        >
            {props.data.map((step, index) => (
            <div key={step.name}>
                {Math.abs(activeStep - index) <= 2 ? (
                <Box
                    sx={{
                    height: 200,
                    display: 'block',
                    overflow: 'hidden',
                    width: '100%',
                    backgroundColor:step.color,
                    }}
                    alt={step.name}
                >   
                  <div key ={step.name+"1"} style={{display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "100%",}} >
                    <Grid
                    container
                    component="main"
                    sx={{ height: '100%' }}
                    >
                      <Grid
                        item
                        xs={false}
                        sm={false}
                        md={6}
                        lg={6}
                        sx={{
                          backgroundImage:  `url(${process.env.PUBLIC_URL + step.photo})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundSize:"50% 90%",
                          backgroundColor: step.color
                        }}
                      />
                      {/* <Grid  item lg={6} md={6} sm ={false} xs={false} style={{ display:"flex", flexDirection: 'column'}}>
                          <img  src={ Photo} style={{ width:"60%", height:"200px", alignSelf:"center"}}/>
                      </Grid> */}
                      <Grid  item lg={6} md={6} sm ={12} xs={12} style={{display:"flex", alignItems:"center", flexDirection:"column", justifyContent:"center"}}>
                          <Typography  component="p" color="text.secondary" variant="h7">
                              {step.name}, {step.profession}
                          </Typography>
                          <p style={{textAlign:"center" }}>
                          {step.temoignage} 
                          </p>
                      </Grid>
                  </Grid>
                  </div>
                </Box>
                ) : null}
            </div>
            ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
            <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
            >
                Next
                {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
                ) : (
                <KeyboardArrowRight />
                )}
            </Button>
            }
            backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
                ) : (
                <KeyboardArrowLeft />
                )}
                Back
            </Button>
            }
        />
    </Box>)
}

const MainPost = () =>{
    return (
        <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${matador})`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src={matador} />}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.3)',
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Typography component="h1" variant="h3" color="inherit" gutterBottom>
              TikTik
            </Typography>
            <Typography variant="h5" color="inherit" paragraph>
              Foyer des enseignants algeriens 
            </Typography>
            <Link variant="subtitle1" href="https://forms.gle/Xdo5bFRXqbKc9Qim9">
              Participer pour un meilleur avenir
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Paper>
    )
}

const SocialMediaLinks = () =>{
    return (
        <>  
            <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                <SocialIcon url="https://facebook.com/jaketrent" style={{margin:15}}/>
                <SocialIcon url="https://instagram.com/jaketrent" style={{margin:15}}/>
                <SocialIcon url="https://youtube.com/jaketrent" style={{margin:15}}/>
            </Grid>
        </>
    )
}


export default function Home() {
return (
    <>
        <Container maxWidth="lg">
            <Grid
            container
            spacing={4}
            >   
                <Grid item xs={12} sx={{mt:3}}  >
                    <MainPost/>    
                </Grid>
                <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                    <Typography component="p" color="text.secondary" variant="h6">
                         Temoignage 
                    </Typography>
                </Grid>
                <Carousel data ={Temoignage}/>    
                <SocialMediaLinks/>    
            </Grid>
        </Container>
        <Copyright/>
        
    </>
  );
}
