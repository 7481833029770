import * as yup from "yup";


export const signupSchema = yup.object({
        lastname :yup.string().required("your_lastname"),
        firstname:yup.string().required("your_firstname"),
        company:yup.string().required("your_company"),
        phone:yup.string()
                 .required("tel_val")
                 .matches(/^[0-9]+$/, "tel_val")
                 .min(10, 'tel_val')
                 .max(13, 'tel_val'),
        email:yup.string().email("email_input").required("email_input"),
        password:yup.string().min(8,"password_min_caracters").max(100,"password_max_caracters").required("password_val"),
        cpassword:yup.string().oneOf([yup.ref("password"), "password_verify"],"password_match").required("confirm_password"),  

    
});