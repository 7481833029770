import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom"
import axios from "axios"
import Loader from "../components/loaders/loader"
import logo from "./logo.png"


const data =[
    {
        membership:"free_trial",
        price:"membership_price_free",
        features:["four_seven_support", "trial_period"],
        color:"#26a69a"
    },
    {
        membership:"gold_member",
        price:"membership_price_gold",
        features:["four_seven_support","member_period","number_students_gold"],
        color:"#ff7043"
    },
    {
        membership:"diamond_member",
        price:"membership_price_diamond",
        features:["four_seven_support", "member_period", "number_students_diamond"],
        color:"#303f9f"
    }
]
export default function SubscribeDisplay() {
    const { t } = useTranslation();
    const history = useHistory();
    const [index, setIndex] = useState("");
    const [disableC, setDisableC] = useState(false);
    const [Isloading, setIsloading] = useState(false);
    const [teacher, setTeacher] = useState("");
    const config = {headers:{
        bearer:localStorage.getItem("bearer")}}
    useEffect(async()=>{
    setIsloading(true)
    try{
        const response = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher", config) 
        setTeacher(response.data)
        if (response.data.membership ==="free")
        {setIndex(0)}
        else if (response.data.membership ==="gold")
        {setIndex(1)}
        else if (response.data.membership ==="diamond")
        {setIndex(2)}
    }catch(err){localStorage.clear(); history.push("/login")}
    setIsloading(false)
    },[])
   return (
    <>  
        <Grid container>
            <Grid item  xs={1} >
                    <span style={{margin:"10%"}}>
                        <img style={{width:"50px",height:"50px",margin:"30%", marginTop:0, marginBottom:0}}  src={logo} />
                    </span>
            </Grid>
            <Grid item xs={11} 
                  style={{justifyContent: document.body.dir ==="ltr" ? "right":"left",
                          display:"flex",
                          alignItems:"center"}}>
                        <Link style={{fontWeight:"bold",
                                    fontFamily:"Segoe UI",
                                    color:"#3f51b5",
                                    margin:"5%",
                                    marginTop:0,
                                    marginBottom:0,
                                    fontSize:10 }}
                                    href = "/dashboard"
                                    underline="always">
                              {t("back_to")} MatadorT.com
                        </Link>
            </Grid>
        </Grid>
        <hr style = {{ backgroundColor:"#FDFAF9"}}/>
        <Grid container>
                <Grid container sx={{ ml:3,mr:3, height:"100%"}} >
                    {Isloading?"":
                        data.map((plan, i) =>{return (
                            <Grid item lg={3} md={3} sm={12} xs={12}
                               style={{display:"flex",
                                       justifyContent:"center",
                                       border:"3px solid #cfd8dc",
                                       borderRadius:"10px",
                                       backgroundColor:"#F5F9FB",
                                       marginLeft:"3.7%",
                                       marginRight:"3.7%",
                                       marginTop:"3%",
                                       height:"80%"}}
                            >
                                <div style={{justifyContent:"center", textAlign:"center"}}>
                                    <h2 style={{color:plan.color, justifyContent:"center"}}> {t(plan.membership)}</h2>
                                    <br/>
                                    <h2 style={{color:"#bdbdbd", justifyContent:"center"}}>{t(plan.price)}</h2>
                                    <br/>
                                    
                                    <Button variant="contained" disabled = {plan.membership=="free_trial"?true:i==index?true:false}> 
                                        {t("subscribe")}
                                    </Button>
                                    <List>
                                        {plan.features.map((feature,i) => {return (
                                                <ListItem disablePadding >
                                                <ListItemButton >
                                                        <ListItemIcon >
                                                            <DoneAllIcon  sx={{ color:"#7cb342"}}/>
                                                        </ListItemIcon>
                                                        <p style={{color:"#26c6da", fontWeight:"bold"}}>{t(feature)}</p>
                                                </ListItemButton>
                                                </ListItem>
                                        )})}
                                     </List>
                                </div>
                            </Grid> )     
                        })}
                </Grid>
        </Grid>
    </>
)}

