import axios from "axios";
import React, {useState, useEffect} from 'react';
import { styled, createTheme, ThemeProvider, alpha } from '@mui/material/styles';
import "../popups/productpopup.css"
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LanguageIcon from '@mui/icons-material/Language';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { blueGrey } from '@mui/material/colors';
import  {MainListItems, SecondaryListItems } from './listItems';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import Previousreturns from "../Dashboard/previousreturns";
import SalesChart from  "../Dashboard/saleschart";
import Dashboard from "../Dashboard/dashboard";
import ProductsDisplay from "../Students/studentsdisplay";
import AccountDropDown from "./dropdowns/accountdropdown";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DropDownMenu from "./dropdowns/dropdownmenu";
import LangDropDown from "./dropdowns/langdropdown";
import LanguageDropDownMenu from "./dropdowns/langmenu";
import Cookies from 'universal-cookie';
import Languages from "../language/languages";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";
import MobileSideMenu from "../popups/mobilesidemenu"

const drawerWidth = 200
const ww = window.innerWidth;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (props) => props.open !== 'open',
})(({ theme, open, drawerwidthleft, drawerwidthright}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerwidthleft  ,
    marginRight:drawerwidthright,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// SearchBar Code
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
     },
}));


const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));



const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight:`calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    
  },
}));

// Search Bar Code


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


export default function Layout(props) {
  const cookies = new Cookies();
  const {t} = useTranslation();
  const [open, setOpen] = React.useState((String(localStorage.getItem("drawer"))=="true"));
  const [opensmall, setOpenSmall] = React.useState(false);
  const [drawerwidthleft, setdrawerwidthleft]= useState("");
  const [drawerwidthright,setdrawerwidthright] = useState("");
  const [langdir, setLang] = useState("");
  const [displaydrawer, setDisplayDrawer] = useState("Large");
  const [direction, setDirection] = useState("")
  const toggleDrawer = () => {
    if (localStorage.getItem("drawer") ==="true"){setOpen(false);localStorage.setItem("drawer",false)}
    else{setOpen(true);localStorage.setItem("drawer",true)}};
    const toggleSmallScreenDrawer = () => {
      setOpenSmall(!opensmall)};
  const [activelayout, SetActiveLayout] = useState("Dashboard");
  // APP bar swithching from left to right
  const currentLanguageCode =(cookies.get("i18next") || "en")
  const currentLanguage = Languages.Languages.find(l => l.code === currentLanguageCode) || "ltr"
  useEffect(() =>{
    if (langdir){
      setdrawerwidthleft(langdir=== "rtl" ? 0 : 200);
      setdrawerwidthright(langdir === "rtl" ? 200 : 0);
      langdir ==="rtl"? setDirection("rtl"):setDirection("ltr")
    } else{
    setdrawerwidthleft(currentLanguage.dir === "rtl" ? 0 : 200);
    setdrawerwidthright(currentLanguage.dir === "rtl" ? 200 : 0);
    currentLanguage.dir ==="rtl"? setDirection("rtl"):setDirection("ltr")
    }
  }, [langdir, currentLanguageCode])
  const history = useHistory()
  const config = {headers:{
    bearer:localStorage.getItem("bearer")}}
  useEffect(async()=>{
    try{
      const response = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/tokenvalidation", config) 
      if (response.data.message ==="token is valid"){
        // if (localStorage.getItem("membership")==="free"){history.push("/subscribe")} activate to route free membership
      }else{localStorage.clear(); history.push("/login")}     
    }catch(err){localStorage.clear(); history.push("/login")}
  },[])
  // get windown width on lunch
  useEffect( () =>{
    if (window.screen.width < 600 && displaydrawer ==="Large"){setDisplayDrawer("Mobile")}
      else if (window.screen.width >= 600 && displaydrawer === "Mobile") {setDisplayDrawer("Large")}
    }, [])
  // get windown width on lunch
  // get windown width on resize
  useEffect( () =>{
    function handleResize() {
      if (window.innerWidth < 600 && displaydrawer ==="Large"){setDisplayDrawer("Mobile")}
      else if (window.innerWidth >= 600 && displaydrawer === "Mobile") {setDisplayDrawer("Large")}
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [displaydrawer])
  // get windown width on resize
  const mdTheme = createTheme({
    direction:direction
  })
  return (  
    <ThemeProvider theme={mdTheme} >
      {drawerwidthleft || drawerwidthright ?
      <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open} drawerwidthleft={drawerwidthleft} drawerwidthright={drawerwidthright}>
        <Toolbar
          style = {{ background: blueGrey[500]}}
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
        {displaydrawer === "Large" ?
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: !drawerwidthright ? '36px' : "",
              marginLeft: drawerwidthright ? '36px' : "",
              padding:drawerwidthright ? 0: "",
              ...(open && { display: 'none' })}}
          >
            <MenuIcon />
          </IconButton>
          :
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSmallScreenDrawer}
            sx={{
              marginRight: !drawerwidthright ? '5%' : "",
              padding:drawerwidthright ? 0: "",
              marginLeft: drawerwidthright ? '2%' : "",
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>}
          <IconButton color="inherit" style={{width:"65%"}} sx={{ flexGrow: 1 }}>
            <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder= {t("search")}
                  inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
          </IconButton>
          <LangDropDown icon ={<LanguageIcon/> } >
                <LanguageDropDownMenu setLang={setLang}/>
          </LangDropDown>
          <AccountDropDown icon ={<AccountCircleIcon/>} >
                <DropDownMenu/>
          </AccountDropDown>
        </Toolbar>
      </AppBar>
      
    {displaydrawer === "Mobile" ?
    opensmall ? <MobileSideMenu trigger ={opensmall} setTrigger = {setOpenSmall} /> : ""
    :
    <Drawer variant="permanent" open={open} >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
       
        <List>
        <MainListItems />
        </List>
        
        <Divider />
        <List>
          <SecondaryListItems/>
        </List>
      </Drawer>}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        {/* COntainer content   */}
          {props.children}
        {/* COntainer content   */}
      </Box>
    </Box>
      :""}
    </ThemeProvider>
  );
}









    