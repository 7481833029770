

const Languages =  [
    {
      code:"en",
      name:"English",
      country_code:"US"
    },
    {
      code:"fr",
      name:"français",
      country_code:"FR"
    },
    {
      code:"ar",
      name:"العربية",
      country_code:"DZ",
      dir:"rtl"
    },
]
export default {
    Languages,
}