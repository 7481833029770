import React, {useState, useEffect} from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import axios from "axios";
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Studentpopup from "../popups/studentpopup";
import LotPopUP from "../popups/lotpopup";
import Layout from "../components/accountlayout"
import CollapsibleTable from "../components/tables/paymentstable"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Qrpopup from "../popups/qrpopup";
import PaymentPopup from "../popups/paymentpopup";
import Loader from "../components/loaders/tableloader";
import HandleRightToLeftDirection from "../components/rtl/rtl"
import {useTranslation} from "react-i18next";

export default function PaymentsDisplay() {
    const {t} = useTranslation();
    const [IsLoading, setIsLoading] = useState(false);
    const [students, SETstudents] = useState([]);
    const [ButtonStudentPopup, SetButtonPpopup] = useState(false);
    const [PaymantButton, setPaymentButton] = useState(false);
    const [ButtonQRPopup, SetButtonQRpopup] = useState(false);
    const [ModButton, SetModButtom]=useState(false);
    const [paymenttomod, SetTobeMod] =useState("");
    const [paymentMod, setPaymentMod] = useState("");
    const [reload, setreload] = useState(1);
    const [payments, setPayments] = useState("");
    const [classes, setClasses] = useState("");
    const [paying, setPaying] = useState("payed");
    const config = {headers:{
        bearer:localStorage.getItem("bearer"),
    }}  
    const MapNfilter = async (students, payments,classes)=>{
        for (var i = 0; i < payments.length; i++){
                const filtered_students = students.filter(student => student.id ===payments[i].student_id)
                const  filtered_classes = classes.filter(classe => classe.id ===payments[i].class_id)
                if (filtered_students.length >0){
                    payments[i].firstname = filtered_students[0].firstname 
                    payments[i].lastname = filtered_students[0].lastname}
                else{
                    payments[i].firstname = ""
                    payments[i].lastname =""}
                if (filtered_classes.length >0){payments[i].classe = filtered_classes[0].name}
                else{ payments[i].classe = ""}  
        }
    return payments}
    useEffect ( async () => {
            setIsLoading(true)
            try {
                const studentsresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/students", config );
                const classesResponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/classes", config ); 
                const paymentsResponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/payment", config );
                if (paymentsResponse.data.message==="Payments not found"){setPayments("")}
                else{
                    SETstudents(studentsresponse.data);
                    setClasses(classesResponse.data)
                    MapNfilter(studentsresponse.data,paymentsResponse.data,classesResponse.data).then(values => setPayments(values))
                }}catch(err){}
            setIsLoading(false)   
    },[reload]);
    useEffect ( async () => {
        if (paymenttomod){
            setPaymentMod(payments.filter(payment => payment.id === parseFloat(paymenttomod))[0])
            if (payments.filter(payment => payment.id === parseFloat(paymenttomod))[0].payment ===0){setPaying("tobepayed")}
         else{setPaying("payed")
        }}
    },[paymenttomod])
    useEffect(()=>{
        console.log(PaymantButton)
    },[PaymantButton])
    return (
    <Layout >
        <HandleRightToLeftDirection>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Title>{t("payments")}</Title>
                                <Box sx={{ flexGrow: 1 }}>
                                    <AppBar position="static" >
                                        <Toolbar sx={{bgcolor:"white"}} >
                                            
                                            <Grid  container  columns={{ sm:12, xs:1}}>
                                                <Grid item sm={4} xs={1} sx={{mt:1,mb:1,mr:1,ml:1}}>
                                                    <Button fontSize={1} 
                                                                variant="contained" 
                                                                fullWidth
                                                                sx ={{bgcolor:"#e0e0e0",
                                                                 color:"black", mt:1,mb:1,mr:1,ml:1}} 
                                                                onClick={()=> setPaymentButton(true)}>
                                                            <Typography fontSize={12} sx ={{fontWeight:"Bold"}}>
                                                                {paymenttomod?t("modify"):t("make_payment")}
                                                            </Typography>
                                                        </Button>
                                                    
                                                    
                                                </Grid>
                                            </Grid>
                                           
                                        </Toolbar>
                                    </AppBar>    
                                </Box>

                            {IsLoading ? <Loader/> :
                            [!payments ? t("empty_database"):   <>  <CollapsibleTable   payments = {payments}
                                                                                        setTrigger={SetButtonQRpopup}
                                                                                        setMod ={SetModButtom}
                                                                                        SetTobeMod = {SetTobeMod}
                                                                                        setPaymentButton={setPaymentButton}
                                                                                        setPaymentMod={setPaymentMod} 
                                                                />
                                                                </>]}
                            {!students ? "":   <>  <PaymentPopup    trigger={PaymantButton}
                                                                    setTrigger = {setPaymentButton}
                                                                    students = {students}
                                                                    setreload={setreload}
                                                                    reload={reload}
                                                                    paymentMod={paymentMod}
                                                                    setPaying={setPaying}
                                                                    paying={paying}
                                                                    paymenttomod={paymenttomod}
                                                                    />
                            </>}
                                                                
                            </Paper>
                        </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
                
     
        </Container>
        </HandleRightToLeftDirection>
    </Layout>
  );
}
