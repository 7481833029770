import  React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import Switch from '@mui/material/Switch';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import FilterListIcon from '@mui/icons-material/FilterList';
import moment from "moment";
import { visuallyHidden } from '@mui/utils';
import CircleIcon from '@mui/icons-material/Circle';
import axios from "axios";
import {useTranslation} from "react-i18next"

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function HeadCells() {
  const {t} = useTranslation();
  return [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: t("class_name"),
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: true,
    label: t("prix_sceance_da"),
  },
]}

export function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx ={{bgcolor: '#78909c'}}>
        <TableCell padding="checkbox" >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
   
          />
        </TableCell>
        {HeadCells().map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx ={{fontWeight:"bold", color:"#f5f5f5"}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export const EnhancedTableToolbar = (props) => {
  const {t} = useTranslation();
  const { numSelected } = props;
  const config = {headers:{
    bearer:localStorage.getItem("bearer"),
  },
  data:Object.fromEntries(props.selected.map((c, index) => [index + 1,c]))}
  const deleteClass = async()=> {
    console.log(config)
    axios.delete("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/classes" ,config).then(
      res=>{
        window.location.reload(false)
      }
    ).catch(
      err=>{})
  }
  const modifyClass = ()=>{
    if (props.numSelected ===1){

      props.SetTobeMod(props.data.filter(Tclass => Tclass.id === props.selected[0])[0].name)
      props.setColor(props.data.filter(Tclass => Tclass.id === props.selected[0])[0].color)
      props.setModID(props.selected[0])
      props.setAdd("none")
      props.setMod("visibility")
    } else{alert("Veuillez choisir une seule classe a modifier !")}
  }
  useEffect (()=>{
    if(props.numSelected !==1){
      props.SetTobeMod("")
      props.setMod("none")
      props.setAdd("visibility")
      props.setModID("")
    }
  },[])
 

  return (
    <Toolbar
      sx={{
        bgcolor:"#607d8b",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected ===1 ? numSelected + " " + t("class_selected"): numSelected + " " + t("classes_selected")}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%', fontWeight:"bold",color:"white"}}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t("my_classes")}
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
        <Tooltip title="Supprimer">
          <IconButton onClick={deleteClass}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Modifer">
          <IconButton onClick={modifyClass} >
            <ModeEditIcon/>
          </IconButton>
        </Tooltip>
        </>
      ) : null }
    </Toolbar>
  );
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
   return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, bgcolor:"#90a4ae" }}  
                hover
                onClick={(event) => props.handleClick(event, row.id)}
                role="checkbox"
                aria-checked={props.isItemSelected}
                tabIndex={-1}
                key={row.name}
                selected={props.isItemSelected}>
          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={props.isItemSelected}
                              inputProps={{
                                'aria-labelledby': props.labelId,
                              }}
                            />
          </TableCell>
          <TableCell component="th"
                        id={props.labelId}
                        scope="row"
                        padding="none" align="center">{row.name}</TableCell>
          <TableCell  align="center">{row.price}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}




export default function CollapsibleTable(props) {
  const {t} = useTranslation();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.classes.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.classes.length) : 0;
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length}
         selected={selected}
          data={props.classes}
          setColor ={props.setColor}
          setMod ={props.setMod}
          setAdd ={props.setAdd}
          SetTobeMod ={props.SetTobeMod}
          setModID = {props.setModID}
          />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.classes.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(props.classes, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      <Row  row={row} handleClick = {handleClick} isItemSelected = {isItemSelected} labelId ={labelId}/>
                      
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.classes.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({page}) =>{
                return `${t("page")}: ${page+1}`
              }}
          labelRowsPerPage ={t("class_per_page")}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}