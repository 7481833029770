import React from "react";
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import { useTimer } from 'react-timer-hook';
import {useTranslation} from "react-i18next"
import "./subscription.css"


export function MyTimer({ expiryTimestamp }) {
    const {t} = useTranslation();
    const leftday = (number) =>{
        if (number.toString().length === 2)
        {return 0 }
        else if (number.toString().length === 3) {return number.toString()[0]}
        else{ return 0}
    }
    const middle = (number) =>{
        if (number.toString().length > 1)
        {return number.toString()[1]}
        else {return 0}
    }
    const rightday = (number) =>{
        if (number.toString().length === 2)
        {return number.toString()[1] }
        else if (number.toString().length === 3)
        {return number.toString()[2] }
        else{return number.toString()[0]}
    }
    const left = (number) =>{
        if (number.toString().length > 1)
        {return number.toString()[0]}
        else{return 0}
    }
    
    const right = (number) =>{
        if (number.toString().length > 1)
        {return number.toString()[1] }
        else{ return number.toString()[0]}
    }
    const {
      seconds,
      minutes,
      hours,
      days,
      months,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });
  return (
      <div style={{textAlign: 'center'}}>
            <div >
                <div className="hAXxjA">
                    <span class="sc-gsTCUz ejJPgH">{t("days")}</span>
                    <div className="calHuP">
                        <span className="mZxSg">{leftday(days)}</span>
                        <span className="mZxSg">{middle(days)}</span>
                        <span className="mZxSg">{rightday(days)}</span>
                    </div>
                </div>
                <div className="hAXxjA">
                    <span class="sc-gsTCUz ejJPgH">{t("hours")}</span>
                    <div className="calHuP">
                        <span className="mZxSg">{left(hours)}</span>
                        <span className="mZxSg">{right(hours)}</span>
                    </div>
                </div>
                <div className="hAXxjA">
                    <span className="sc-gsTCUz ejJPgH">{t("minutes")}</span>
                    <div className="calHuP">
                        <span className="mZxSg"> {left(minutes)}</span>
                        <span className="mZxSg">{right(minutes)}</span>
                    </div>
                </div>
                <div className="hAXxjA">
                    <span class="sc-gsTCUz ejJPgH">{t("seconds")}</span>
                    <div className="calHuP">
                        <span className="mZxSg">{left(seconds)}</span>
                        <span className="mZxSg">{right(seconds)}</span>
                    </div>
                </div>
        </div>
      </div>
)}