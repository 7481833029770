import React, {useState, useEffect, useRef} from 'react';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import TextField from "@material-ui/core/TextField"
import Paper from '@mui/material/Paper';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Layout from "../components/accountlayout"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@mui/material/Select';
import QrReader from "react-qr-reader";
import {useTranslation} from "react-i18next";
import HandleRightToLeftDirection from "../components/rtl/rtl";
import Loader from "../components/loaders/tableloader";

export default function ScanPresence() {
    const {t} = useTranslation();
    const [APIerror, setAPIERROR] = useState("");
    const [APIresponse, setAPIresponse] = useState("");
    const [IsLoading, setIsloading] = useState(false);
    const [classes, SetClasses] = useState([]);
    const [classe, SetClasse] = useState('');
    const [DisplayScanner, setDisplayScanner] = useState("");
    const [scanResultFile, setScanResultFile] =useState("");
    const [students, Setsutdents] = useState("");
    const [student, setStudent] = useState("");
    const [payed, setPayed] = useState(0);
    const [tobepayed, setTobePayed] = useState(0);
    const qrRef = useRef(null);
    const handleErrorFile =(error) =>{}
    const config = {headers:{
        bearer:localStorage.getItem("bearer"),
        }}
    const handlePresence = async (e) =>{
        e.preventDefault()
        if (scanResultFile){
            const data = {
                studentid: scanResultFile,
                classtype:classe,}
            try {
                const cresponse = await axios.put(`https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/scanner/${scanResultFile}`, data, config  );
                if (cresponse.data.message==="Student presence recorded"){
                    setAPIresponse(t("attendance_added"));
                    setScanResultFile("");
                    setAPIERROR("");
                }else{ setAPIresponse("")}
            }catch(err){if (err.response.data.message==="Please update student classe"){setAPIERROR(t("update_student_classe"))}
            }}}
    const handleScanwebCam = async (result) =>{
         if (result){
            if (students.filter(student => student.id === parseFloat(result))[0]){
            setStudent(students.filter(student => student.id === parseFloat(result))[0])
            setScanResultFile(result)
            const payment_response = await axios.get(`https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/payment/${result}`, config  )
                if(payment_response.data ==="Payments in header"){
                    setPayed(payment_response.headers.payed)
                    setTobePayed(payment_response.headers.tobepayed) }
            }}} 
    const handleChange = (event) => {
        SetClasse(event.target.value);
        if (event.target.value !== ""){
            setDisplayScanner("block")
        }else{setDisplayScanner("")}
      }
    useEffect ( async () => {
        setIsloading(true);
        const config = {headers:{
                bearer:localStorage.getItem("bearer"),}}  
        try {
            const studentsresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/students", config );
            const classesresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/classes", config );
            if (classesresponse.data.message==="Teacher has no classes"){SetClasses("")}
            else{SetClasses(classesresponse.data);
                Setsutdents(studentsresponse.data)}
            } catch(err){}
        setIsloading(false);
    },[]);
return (
    <Layout >
        <HandleRightToLeftDirection>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        
                <Grid container spacing={3}>
                
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Title>{t("scan_attendance")}</Title>
                                 {!APIerror ? ""  : <Alert severity="error"> {APIerror}</Alert>} 
                                 {!APIresponse ? "" : <Alert severity="success"> {APIresponse}</Alert>}
                                {IsLoading ? <Loader/> :
                                [!students ? t("empty_database"): <> <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                                        <InputLabel id="demo-simple-select-error-label">{t("choose_class")}</InputLabel>
                                                                        <Select
                                                                        labelId="demo-simple-select-error-label"
                                                                        id="demo-simple-select-error"
                                                                        value={classe}
                                                                        label={t("class_name")}
                                                                        onChange={handleChange}
                                                                        renderValue={(value) => `🕗  - ${value}`}
                                                                        >
                                                                            <MenuItem key="1#1" value="">
                                                                                <em>{t("none")}</em>
                                                                            </MenuItem>
                                                                            {classes.map((classe)=>(
                                                                                <MenuItem key ={classe.name} value={classe.name}>{classe.name}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        <FormHelperText>{t("required")}</FormHelperText>
                                                                       </FormControl>
                                                                   </>]}
                                 
                                <Grid  sx={{ alignSelf:"center", justifySelf:"center", width:"40%",display:DisplayScanner}} >
                                        { !DisplayScanner ? "": (
                                                                <>
                                                                        <QrReader
                                                                        delay={100}
                                                                        style={{width:"100%"}}
                                                                        onError ={handleErrorFile}
                                                                        onScan = {handleScanwebCam}
                                                                    />
                                                                </>)}
                                </Grid>
                                { !scanResultFile ? "" :  (
                                                    <>
                                                        <Grid container columns={{ md:12, xs:1}} sx={{mt:1, mb:1}}>
                                                            <Grid xs={1} md ={12} sx={{mt:1, mb:1}}>
                                                                <Typography style={{textAlign:"center"}}>
                                                                    [{student.lastname} {student.firstname}]
                                                                </Typography>
                                                            </Grid>
                                                            <Grid xs={1} md ={12} sx={{mt:1, mb:1}}>
                                                                <Typography style={{ color: parseFloat(student.payed)>0?"green":"red", textAlign:"center" }}>
                                                                    [Montant payé: {payed} DA]
                                                                </Typography>
                                                            </Grid>
                                                            <Grid xs={1} md ={12} sx={{mt:1, mb:1}}>
                                                                <Typography style={{ color: parseFloat(student.payed)>0?"green":"red", textAlign:"center" }}>
                                                                    [Montant a payé: {tobepayed} DA]
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        <Button variant="contained"
                                                                sx ={{bgcolor:"#e0e0e0", color:"black", width:"100%"}} 
                                                                onClick={handlePresence}>
                                                            {t("record_attendance")}
                                                        </Button>
                                                    </>)}
                            </Paper>
                           
                        </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
        </Container>
        </HandleRightToLeftDirection>
    </Layout>
  );
}
