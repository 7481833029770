import * as yup from "yup";


export const resetpasswordSchema = yup.object({
    
    password:yup.string().min(8,"Le mot de passe doit contenir au mois 8 caractere").max(100,"Le mot de passe doit contenir au maximum 100 caractere").required("Veuillez saisir votre mot de passe"),
    cpassword:yup.string().oneOf([yup.ref("password"), "Veuillez vérifier votre mot de passe"],"Les deux mots de passe doivent etre identique").required("Veuillez confirmer votre mot de passe"),  

       
    
});