
import React, {useEffect}  from 'react';
import {Switch, Route} from 'react-router-dom';
import Login from "./Pages/session/login/login"
import Signup from "./Pages/session/signup/signup"
import ForgotPassword from "./Pages/session/forgotpassword/forgotpassword"
import ResetPassword from "./Pages/session/forgotpassword/resetpassword"
import {ProtectedRoute} from "./Pages/Auth/protected.route"
import ProductsDisplay from "./Pages/Students/studentsdisplay"
import Dashboard from "./Pages/Dashboard/dashboard"
import ApplicationDisplay from "./Pages/applications/applications"
import ClassesDisplay from "./Pages/classes/classes"
import ScanPresence from "./Pages/presence/presence"
import Scheduler from "./Pages/scheduler/scheduler"
import LocationDisplay from "./Pages/location/location"
import ModifyAccount from "./Pages/account/account"
import PresencesDisplay from "./Pages/presences/presences"
import PaymentsDisplay from "./Pages/payments/payments"
import SubscribeDisplay from "./Pages/subscriptions/subscribe"
import MySubscription from "./Pages/subscriptions/mysubscription"
import Cookies from 'universal-cookie'
import Languages from "./Pages/language/languages";
import ActivateAccount from "./Pages/session/activateaccount/activateaccount"
import PreLunch from "./Pages/pre-lunch/prelunch"
import Home from "./Pages/home/home"    

function App(){
    const cookies = new Cookies();
    const currentLanguageCode =(cookies.get("i18next") || "en")
    const currentLanguage = Languages.Languages.find(l => l.code === currentLanguageCode)
    useEffect(() =>{
    document.body.dir = currentLanguage.dir || "ltr"
    }, [currentLanguageCode, currentLanguage.dir])
    return (
    
    <Switch>
        <Route exact path ="/login" component = {Login}/>
        <Route exact path ="/signup" component = {Signup}/>
        <Route exact path ="/forgotpassword" component = {ForgotPassword}/>
        <Route exact path ="/resetpassword/:id" component = {ResetPassword}/>
        <Route exact path ="/activate/:id" component = {ActivateAccount}/>  
        <ProtectedRoute exact path="/dashboard" component= {Dashboard} />
        <ProtectedRoute exact path = "/applications" component = {ApplicationDisplay} />
        <ProtectedRoute exact path="/students" component={ProductsDisplay} />
        <ProtectedRoute exact path = "/presence" component = {ScanPresence} />
        <ProtectedRoute exact path = "/classes" component = {ClassesDisplay} /> 
        <ProtectedRoute exact path = "/calendar" component = {Scheduler} />
        <ProtectedRoute exact path = "/location" component = {LocationDisplay} />
        <ProtectedRoute exact path = "/account" component = {ModifyAccount} /> 
        <ProtectedRoute exact path = "/presences" component = {PresencesDisplay} />  
        <ProtectedRoute exact path = "/payments" component = {PaymentsDisplay} /> 
        <ProtectedRoute exact path = "/subscribe" component = {SubscribeDisplay} /> 
        <ProtectedRoute exact path = "/mysubscription" component = {MySubscription} /> 
        <Route exact path = "/prelunch" component = {PreLunch}/>
        <Route exact path = "/" component = {Home}/>
        {/* Render 404 pages for all routes that hasn't been created and has to be the last route */}
        <Route path = "*" component = {() => "404 NOT FOUND"}/>
        
    </Switch>
  

    )

}
export default App;



// class App extends Component {
    

//     render() { 
//         return (
            
//                 <Switch>
//                    <Route exact path ="/login" component = {Login}/>
//                    <Route exact path ="/signup" component = {Signup}/>
//                    <Route exact path ="/Dashboard" component = {Dashboard}/>
//                    <Route exact path ="/forgotpassword" component = {ForgotPassword}/>
//                    <Route exact path ="/resetpassword/:id" component = {ResetPassword}/>
//                    <ProtectedRoute exact path = "/protectedroute" component = {Dashboard} auth={false} /> 


//                    {/* Render 404 pages for all routes that hasn't been created and has to be the last route */}
//                    <Route path = "*" component = {() => "404 NOT FOUND"}/>
                   
//                 </Switch>
             
//          );
//     }
// }
 
// export  default App;
