import React, {useState, useEffect, useRef} from 'react';
import Link from '@mui/material/Link';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Layout from "../components/accountlayout"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {GoogleMap, withScriptjs, withGoogleMap, Marker} from "react-google-maps";
import QRCode from "qrcode";
import {useTranslation} from "react-i18next";

function Map(){
    const [isloading, setisloading]=useState(false)
    const [Lat, setLat]=useState(36.7525)
    const [Lng, setlng]=useState(3.04197)
    useEffect ( async () => {
        setisloading(true)
        const config = {headers:{
                bearer:localStorage.getItem("bearer"),}}  
        try {
            const locationresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/location", config );
            if(locationresponse.data.message !=="Teacher has no locations"){
                if(Lat !== locationresponse.data[0].lat){setLat(locationresponse.data[0].lat)}
            if(Lng !==locationresponse.data[0].lng){setlng(locationresponse.data[0].lng)}
            }else{}
            setisloading(false)
        } catch(err){}
    },[]);
    const onMarkerDragEnd = async (coor)=>{
        setLat(coor.latLng.lat());
        setlng(coor.latLng.lng());
        const config = {headers:{
                bearer:localStorage.getItem("bearer"),}} 
        const body = {lat:coor.latLng.lat(),
                      lng:coor.latLng.lng()}
        try {
            const locationresponse = await axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/location", body, config);
            } catch(err){}
    }
    return (
        <>  
            {isloading ? "" : (
                <GoogleMap 
                defaultZoom={10}
                defaultCenter={{lat:Lat, lng:Lng}} 
                >
                    <Marker 
                    draggable={true}
                    onDragEnd={onMarkerDragEnd}
                    position={{ lat: Lat, lng: Lng }}
                    />
                </GoogleMap>
            )}
        </>
        
    )
}
const WrappedMap = withScriptjs(withGoogleMap(Map))

export default function LocationDisplay() {
    const {t} = useTranslation();
    const [APIerror, setAPIERROR] = useState("");
    const [isloading, setisloading]=useState(false);
    const [update, setUpdate] = useState(false);
    const [QRdownload, SetQRdownload] = React.useState("");
    const [APIresponse, setAPIresponse] = useState("");
    const [defaultLocation, setDefaultlocation] = useState(false);
    const [location,setlocation] =useState("http://maps.google.com/maps?q=36.69125420606356,6.590755964667312");
    const SaveCoordinates =async()=>{
        setUpdate(true)
        setAPIresponse("")
    }
    const previousValues = useRef({ location });
    const makeApiCall = async() =>{
        setisloading(true)
        const config = {headers:{
                bearer:localStorage.getItem("bearer"),}}  
        try {
            const locationresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/location", config );
            if(locationresponse.data.message !=="Teacher has no locations"){
                setlocation(`http://maps.google.com/maps?q=${locationresponse.data[0].lat},${locationresponse.data[0].lng}`)
            }else{setDefaultlocation(true)}
            setisloading(false)
        } catch(err){}
    }
    // First use Effect is for first page loading
    useEffect ( async () => {
        makeApiCall()
    },[]);
    // Second use Effect is for in case stuff gets changed to re order data
    useEffect ( async () => {
        makeApiCall()}
    ,[update]);
     async function generateQRCode () {
            try {
                const response = await QRCode.toDataURL(location.toString(), {width:220,heigth:220})
                SetQRdownload(response)
                }catch (error){ 
                }}
    const updateLocation = async () => {
        setAPIresponse(t("coordinates_updated"))
        setDefaultlocation(false)
        setUpdate(false)
        SetQRdownload("")
    }
return (
    
    <Layout >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Title>{t("my_location")}</Title>
                                 {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>}
                                 {!APIresponse ? "" : <Alert severity="success"> {APIresponse}</Alert>}
                                 {!update ? (
                                         <>
                                            <Button sx={{mt:2,
                                                            mb:2,
                                                            bgcolor:"#90a4ae",
                                                            color:"white",
                                                            ":hover":{bgcolor:"#546e7a"}}}
                                                            onClick={SaveCoordinates}>
                                                                {t("update_my_location")}
                                            </Button> 
                                            {defaultLocation ? "": (
                                                <>
                                                    <Button sx={{mt:2,
                                                                mb:2,
                                                                bgcolor:"#90a4ae",
                                                                color:"white",
                                                                ":hover":{bgcolor:"#546e7a"}}}
                                                                onClick={generateQRCode}>
                                                        {t("generate_qr_code")}
                                                    </Button>
                                                    <Box style={{alignSelf:'center',
                                                    justifySelf:"center"}}
                                                        sx={{mt:2}}>
                                                        <a href={QRdownload} download="QR code">
                                                            <img src = {QRdownload} />
                                                        </a>
                                                    </Box>
                                                </>
                                            )} 
                                        </>
                                 ): (<>
                                        <div style={{width:"80%", heigth:"50%", alignSelf:"center", justifySelf:"center"}} >
                                            <WrappedMap 
                                                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDRcgoEA9aoYk0Bsp50dqW280ZtcRCJCU8`}
                                                loadingElement={<div style={{ height: `100%` }} />}
                                                containerElement={<div style={{ height: `400px` }} />}
                                                mapElement={<div style={{ height: `100%` }} />}
                                            />
                                        </div>
                                        <Button sx={{mt:2,
                                            mb:2,
                                            bgcolor:"#90a4ae",
                                            color:"white",
                                            ":hover":{bgcolor:"#546e7a"}}}
                                            onClick={updateLocation}>
                                                     {t("back")}
                                        </Button> 
                                    </>
                                 )}
                            </Paper>
                        </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
          
        </Container>
            
    </Layout>
  );
}
