import * as yup from "yup";


export const PaymentConditionalSchema = (variable) =>{
        if (variable ==="payed"){
                const PaymentSchema = yup.object({
                        name :yup.string().required("select_student"),
                        payed:yup.number().integer("amount_number").min(0).max(100000000000).typeError("amount_number").required("tape_price"),
                        tobepayed:yup.number().integer("amount_number").min(0).max(100000000000).typeError("amount_number"),
                        paymentdate:yup.date().required(),
                });
                return PaymentSchema
        }
        else{
                const PaymentSchema = yup.object({
                        name :yup.string().required("select_student"),
                        payed:yup.number().integer("amount_number").min(0).max(100000000000).typeError("amount_number"),
                        tobepayed:yup.number().integer("amount_number").min(0).max(100000000000).typeError("amount_number").required("tape_price"),
                        paymentdate:yup.date().required(),
                });
                return PaymentSchema
        }
}