import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {useFormik} from "formik";
import {resetpasswordSchema} from "../../Validations/resetpasswordval";
import {useState, useEffect} from "react";
import axios from "axios";
import {useHistory} from "react-router-dom" 
import Copyright from "../../components/copyright"
import {useTranslation} from "react-i18next"


const theme = createTheme();

export default function ActivateAccount() {
  const {t} = useTranslation(); 
  let history = useHistory();
  const [APIerror, setAPIERROR] = useState("")
  const [APIpositive, setAPIPOSITIVE] = useState("")
  useEffect( ()=>{
  //   Check if link is expired and activate account
  const data = {
    url:window.location.href,
    active:"true"
  }
  console.log(window.location.href)
  axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/activate", data ).then( 
    res => {
      // Votre compte est activé
      setAPIPOSITIVE(res.data.message)
      history.push("/login")
    }
  ).catch(
    err=>{  
      if (err.response.status ===401){
      setAPIERROR(err.response.data.message)};
      })
  //   Check if link is expired and activate account       
})
// Checking if authenticated then redirect to Dashboard
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <VisibilityTwoToneIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("activate")}
          </Typography>
          {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>} 
          {APIpositive.length  === 0 ? ""  : <Alert severity="success"> {APIpositive}</Alert>}   
            
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2" >
                         {t("back_login")}
                </Link>
              </Grid>
              <Grid item>
              
              </Grid>
            </Grid>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}