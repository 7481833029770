
import React, {useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from "@material-ui/core/TextField"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import axios from "axios"
import Auth from "../../Auth/auth"
import {useState} from "react";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next"
import HandleRightToLeftDirection from "../../components/rtl/rtl"
import Copyright from "../../components/copyright"
const theme = createTheme();

export default function  Login(){
  let history = useHistory();
  // Checking if authenticated then redirect to Dashboard
 useEffect( ()=>{
  if (localStorage.getItem("isAuth")){
    history.push("/dashboard")}        
  })
// Checking if authenticated then redirect to Dashboard
  const {t} = useTranslation();
  const [email, setEMAIL] = useState("")
  const [password, SETPASSWORD] = useState("")
  const [APIerror, setAPIERROR] = useState("")
  const [disableB, setDisableB] = useState(false)
  const handleSubmit = async (e) =>{
    e.preventDefault();
    setDisableB(true)
  const body={ email: email, password: password,}
  axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/login", body ).then(
      res=>{
        localStorage.setItem("bearer",res.headers.bearer);
        localStorage.setItem("publicid",res.headers.publicid);
        localStorage.setItem("drawer",res.headers.open);
        localStorage.setItem("membership",res.headers.membership);
        Auth.login(() => { history.push("/dashboard") }) ;
      }
    ).catch(
      err=>{
        setDisableB(false)  
        setAPIERROR(err.response.data)       
      }
    )
}
return (
    
    <HandleRightToLeftDirection>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          sx={{
            backgroundImage:  `url(${process.env.PUBLIC_URL + '/School.jpg'})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("login")}
            </Typography>
            <Box component="form"   onSubmit={handleSubmit} sx={{ mt: 1 }}>
            
             {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>} 

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t("email")}
                name="email"
                autoComplete="email"
                autoFocus
                onChange = { (event) => setEMAIL(event.target.value)  }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("my_password")}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange = { (event) => SETPASSWORD (event.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={t("remember_me")}
              />
              <Button
                disabled ={disableB}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t("login")}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/forgotpassword" variant="body2">
                    {t("forgot_passwword_?")}
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {t("account_signup")}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </HandleRightToLeftDirection>
  );
}