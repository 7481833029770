import React, {useState, useEffect} from 'react';
import "./productpopup.css"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid  from '@mui/material/Grid';
import Box  from '@mui/material/Box';
import Avatar  from '@mui/material/Avatar';
import Typography  from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import {StudentSchema} from "../Validations/studentvalidation"
import {useFormik} from "formik";
import axios from "axios";
import Loader from "../components/loaders/loader";
import {useTranslation} from "react-i18next";
import HandleRightToLeftDirection from "../components/rtl/rtl";
import {RtlOutput} from "../components/rtl/rtldropdown";


function Studentpopup(props) {
     const {t} = useTranslation();
     const [APIerror, setAPIERROR] = useState("")
     const [IsLoading, setIsLoading] = useState(false);
     const [classes, SetClasses] = useState([]);
     const [APIresponse ,setAPIresponse] = useState("");
     let TxtDir = RtlOutput()
     const config = {headers:{
          bearer:localStorage.getItem("bearer"),
      }} 
     const formik = useFormik({
          initialValues:{
            lastname:props.studentMod.lastname,
            firstname:props.studentMod.firstname,
            classe:props.studentMod.classe?props.studentMod.classe:"",
            phone:props.studentMod.phone,
            studentnote:props.studentMod.studentnote,
            tobemod: props.studentTobeModfied[0]?props.studentTobeModfied[0]:"false" ,
          },
          onSubmit: async( values) =>{
            axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/students", values ,config).then(
                  res=>{
                       props.setreload(props.reload+1)
                       if (res.data.message==="Student added successfuly!"){
                         setAPIresponse(t("student_add_success"))
                       }else{setAPIresponse(t("student_modify_success"))}
                   }
                ).catch(
                  err=>{  
                    if (err.response.status ===401){
                    setAPIERROR(err.response.data.message)};
                    })
          },
          validationSchema:StudentSchema,
          enableReinitialize:true,
      });
      useEffect ( async () => {
          setIsLoading(true);
          const config = {headers:{
                  bearer:localStorage.getItem("bearer"),}}  
          try {
              const classesresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/classes", config );
              if (classesresponse.data.message==="Teacher has no classes"){SetClasses("")}else{SetClasses(classesresponse.data)}
              } catch(err){}
          setIsLoading(false);
      },[]);
      useEffect (  () => {
          setAPIresponse("")
          setAPIERROR("")
      },[props.trigger]);
    return (props.trigger) ? ( 
     <HandleRightToLeftDirection>
      <div className="popup">
               {localStorage.getItem("drawer") ===true ?"":""}
               <div className={ TxtDir ==="rtl"?
               (localStorage.getItem("drawer") ==="true" ?"popup-inner-rtl-drawer":"popup-inner-rtl"):
               (localStorage.getItem("drawer") ==="true" ?"popup-inner-drawer":"popup-inner" )} >
                              <Box component="form"   onSubmit={formik.handleSubmit} sx={{ mt: 0.5 }}>
                                   {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>}
                                   {!APIresponse? "":<Alert severity="success"> {APIresponse}</Alert>}
                                   {IsLoading ? <Loader/> :
                                   [!classes ? props.setTrigger(false): 
                                        <>
                                             <TextField
                                                  fullWidth
                                                  select
                                                  id="classe"
                                                  name="classe"
                                                  size="small"
                                                  sx={{ mt: 0.5, mb:0.5}}
                                                  label={t("class_name")}
                                                  value = {formik.values.classe}
                                                  onChange = { formik.handleChange  }
                                                  error = {formik.touched.classe && !!formik.errors.classe}
                                                  helperText = {t(formik.touched.classe && formik.errors.classe)}
                                                  >
                                                  {classes.map((classe) => (
                                                            <MenuItem key={classe.name}  value={classe.name}>{classe.name}</MenuItem> 
                                                       ))
                                                  }
                                             </TextField>

                                             <TextField
                                                  margin="normal"
                                                  fullWidth
                                                  size="small"
                                                  id="lastname"
                                                  sx={{ mt: 0.5 }}
                                                  label={t("lastname")}
                                                  name="lastname"
                                                  autoComplete="lastname"
                                                  autoFocus
                                                  value = {formik.values.lastname}
                                                  onChange = { formik.handleChange  }
                                                  error = {formik.touched.lastname && !!formik.errors.lastname}
                                                  helperText = {t(formik.touched.lastname && formik.errors.lastname)}
                                                  
                                             />
                                             <TextField
                                                  margin="normal"
                                                  fullWidth
                                                  size="small"
                                                  id="firstname"
                                                  sx={{ mt: 0.5 }}
                                                  label={t("firstname")}
                                                  name="firstname"
                                                  autoComplete="firstname"
                                                  autoFocus
                                                  value = {formik.values.firstname}
                                                  onChange = { formik.handleChange  }
                                                  error = {formik.touched.firstname && !!formik.errors.firstname}
                                                  helperText = {t(formik.touched.firstname && formik.errors.firstname)}
                                             />
                                             <TextField
                                                  margin="normal"
                                                  fullWidth
                                                  size="small"
                                                  id="phone"
                                                  sx={{ mt: 0.5 }}
                                                  label={t("tel")}
                                                  name="phone"
                                                  autoComplete="phone"
                                                  autoFocus
                                                  value = {formik.values.phone}
                                                  onChange = { formik.handleChange  }
                                                  error = {formik.touched.phone && !!formik.errors.phone}
                                                  helperText = {t(formik.touched.phone && formik.errors.phone)}
                                             />
                                             <TextField
                                                  margin="normal"
                                                  fullWidth
                                                  size="small"
                                                  sx={{ mt: 0.5, mb:0.5 }}
                                                  multiline
                                                  rows={4}
                                                  name="studentnote"
                                                  label={t("student_note")}
                                                  id="studentnote"
                                                  autoComplete="studentnote"
                                                  value = {formik.values.studentnote}
                                                  onChange = { formik.handleChange  }
                                                  error = {formik.touched.studentnote && !!formik.errors.studentnote}
                                                  helperText = {t(formik.touched.studentnote && formik.errors.studentnote)}
                                             />
                                             <Button
                                                  type="submit"
                                                  fullWidth
                                                  size="small"
                                                  
                                                  sx={{ mt: 0.5, mb: 0.5, bgcolor:"#81c784", color:"white",":hover":{bgcolor:"#43a047"} }}
                                             >
                                                  {!props.ModButton?t("add_student"):t("modify")}
                                             </Button>
                                             <Button
                                                  
                                                  sx={{bgcolor:"#ef9a9a", mt:0.5,color:"white",":hover":{bgcolor:"#ef5350"} }}
                                                  fullWidth
                                                  size="small"
                                                  onClick ={() => props.setTrigger(false)}
                                             >
                                                  {t("close")}
                                             </Button>
                                        </>]} 
                              </Box>
                         {props.children}
               </div>
      </div>
     </HandleRightToLeftDirection>

     ):""  ;
}

export default Studentpopup;