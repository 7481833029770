import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import {useHistory} from "react-router-dom"
import { useTranslation } from "react-i18next";

export  const MainListItems = () => {
  let history = useHistory();
  const {t} = useTranslation();
  return (
  <div>
    <ListItem button  onClick = {()=>{ history.push("/dashboard")}}> 
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary={t("dashboard")}/>
    </ListItem>
    <ListItem button onClick = {()=>{ history.push("/applications")}}>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary={t("application")} />
    </ListItem>
    <ListItem button onClick = {()=>{ history.push("/students")}}>
      <ListItemIcon>
        < PeopleIcon />
      </ListItemIcon>
      <ListItemText primary={t("students")} />
    </ListItem>
    <ListItem button onClick = {()=>{ history.push("/classes")}}>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary={t("classes")} />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AutoAwesomeMotionIcon />
      </ListItemIcon>
      <ListItemText primary={t("presence")} />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary={t("reports")} />
    </ListItem>
    </div>
)};

export const SecondaryListItems = () =>{
  const {t} = useTranslation();
return (
  <div>
    <ListSubheader inset>{t("reports")} </ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
)}
