import React, {useState, useEffect, useRef} from 'react';

export function RtlOutput () {
  const [TxtDir,setTxtDir] = useState("ltr");
  //  get initial direction changes from dom
  React.useLayoutEffect(() => {
      document.body.getAttribute("dir") ==="ltr" ? setTxtDir("ltr") : setTxtDir("rtl")
    }, [TxtDir]);
  //  get instant direction changes from dom
   useEffect(() => {
      const observer = new MutationObserver((mutationsList, observer) => {
        if (mutationsList.some((mutation) => mutation.attributeName === "dir")) {
          document.body.dir ==="ltr" ? setTxtDir("ltr") : setTxtDir("rtl")
        } 
      });
      observer.observe(document.body, {
        attributes: true
      });
      return () => observer.disconnect();
    }, []);
    return TxtDir
    } 
  