import React, {useState, useEffect} from 'react';
import "./productpopup.css"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid  from '@mui/material/Grid';
import Box  from '@mui/material/Box';
import Avatar  from '@mui/material/Avatar';
import Typography  from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import  {MainListItems, SecondaryListItems } from '../components/listItems';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ClickAwayListener from '@mui/material/ClickAwayListener';

function MobileSideMenu(props) {
    const handleClickAwawy = () =>{
        props.setTrigger(false)}
    return (props.trigger) ? ( 
      <div className="popup-menu">
        <ClickAwayListener onClickAway ={handleClickAwawy}>
        <div className="popup-inner-menu">
                  <List>
                  <MainListItems />
                  </List>
                  <Divider />
                  <List>
                    <SecondaryListItems/>
                  </List>
        </div>
        </ClickAwayListener>
      </div>
):""
}

export default MobileSideMenu;