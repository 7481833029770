import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import Switch from '@mui/material/Switch';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import FilterListIcon from '@mui/icons-material/FilterList';
import moment from "moment";
import { visuallyHidden } from '@mui/utils';
import QRCode from "qrcode";
import axios from "axios";
import { formLabelClasses } from '@mui/material';
import {useTranslation} from "react-i18next"

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function HeadCells() {
  const {t} = useTranslation();
  return [
    {
      id: 'lastname',
      numeric: false,
      disablePadding: true,
      label: t("lastname"),
    },
    {
      id: 'firstname',
      numeric: false,
      disablePadding: true,
      label: t("firstname"),
    },
    {
      id: 'payed',
      numeric: true,
      disablePadding: false,
      label: t("amount_payed") + " (" + t("da") + ")" ,
    },
    {
      id: 'tobepayed',
      numeric: true,
      disablePadding: false,
      label: t("amount_tobepayed") + " (" + t("da") + ")",
    },
    {
      id: 'classe',
      numeric: true,
      disablePadding: false,
      label: t("class_name"),
    },
    {
      id: 'date',
      numeric: true,
      disablePadding: false,
      label: t("date"),
    },
  ]
} 

export function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx ={{bgcolor: '#78909c'}}>
        <TableCell padding="checkbox" key={"Checkbox"}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
   
          />
        </TableCell>
        {HeadCells().map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx ={{fontWeight:"bold", color:"#f5f5f5"}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export const EnhancedTableToolbar = (props) => {
  const {t} = useTranslation();
  const { numSelected } = props;
  const config = {headers:{
    bearer:localStorage.getItem("bearer"),
  },
  data:Object.fromEntries(props.selected.map((c, index) => [index + 1,c]))}
  const deletePayment = async()=> {
    axios.delete("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/payment" ,config).then(
      res=>{
        window.location.reload(false)
      }
    ).catch(
      err=>{  
        if (err.response.status ===401){}
        })
          }
  const modifyClass = ()=>{
    if (props.numSelected ===1){
      props.SetTobeMod(props.selected)
      props.setMod(true)
      props.setPaymentButton(true)
    } else{
      alert("Veuillez choisir un seule payment a modifier !")
    }
  }
  useEffect ( async () => {
    if (props.numSelected ===0){
      props.setPaymentMod("")
      props.SetTobeMod("")
      props.setMod(false)
    }
},[props.numSelected]);

  return (
    <Toolbar
      sx={{
        bgcolor:"#607d8b",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected ===1 ? numSelected +" " + t("payment_selected"): numSelected + " " + t("payments_selected")}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%', fontWeight:"bold",color:"white"}}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {t("payments")}
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Supprimer">
            <IconButton onClick={deletePayment}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Modifer">
            <IconButton onClick={modifyClass} >
              <ModeEditIcon/>
            </IconButton>
          </Tooltip>
          
        </>
      ) : null }
    </Toolbar>
  );
};




function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [Text, setText] = React.useState("");
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, bgcolor:"#90a4ae" }}  
                hover
                onClick={(event) => props.handleClick(event, row.id)}
                role="checkbox"
                aria-checked={props.isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={props.isItemSelected}>
        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={props.isItemSelected}
                            inputProps={{
                              'aria-labelledby': props.labelId,
                            }}
                          />
        </TableCell>
        <TableCell component="th" scope="row" align="center">{row.lastname}</TableCell>
        <TableCell align="center">{row.firstname}</TableCell>
        <TableCell align="center">{row.payment}</TableCell>
        <TableCell align="center">{row.tobepayed}</TableCell>
        <TableCell align="center">{row.classe}</TableCell>
        <TableCell align="center">{moment(row.payment_date).format('YYYY-MM-DD, hh:mm:ss a')}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default function CollapsibleTable(props) {
  const {t} = useTranslation();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
 const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.payments.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.payments.length) : 0;
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length}
                              selected={selected}
                              data={props.payments}
                              setTrigger={props.setTrigger}
                              setMod={props.setMod}
                              SetTobeMod={props.SetTobeMod}
                              setPaymentButton={props.setPaymentButton}
                              setPaymentMod={props.setPaymentMod}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'small'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.payments.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                 
              {
                stableSort(props.payments, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      <Row key={row.id} row={row} handleClick = {handleClick} isItemSelected = {isItemSelected} labelId ={labelId}/>
                      
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.payments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={({page}) =>{
                return ` ${t("page")}: ${page+1}`
              }}
          labelRowsPerPage ={t("payment_per_page")}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}