import React, {useState, useEffect} from 'react'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import IconButton from '@mui/material/IconButton';
import { blueGrey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import HelpIcon from '@mui/icons-material/Help';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import {useHistory} from "react-router-dom"
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import {Logout} from "../../Auth/logout/logout"
import {useTranslation} from "react-i18next";
import HandleRightToLeftDirection from "../rtl/rtl"
import {RtlOutput} from "../rtl/rtldropdown";

const AccountMenu = () => {
    const {t} = useTranslation();
    let history = useHistory();
    return (
    
    <div>
      <HandleRightToLeftDirection>
      <ListItem key="account" button  onClick = {()=>{ history.push("/account")}}> 
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={t("my_account")} secondary={t("account_details")}/>
      </ListItem>
      <ListItem key="location" button onClick = {()=>{ history.push("/location")}}>
        <ListItemIcon>
          <EditLocationIcon />
        </ListItemIcon>
        <ListItemText primary={t("localization")} />
      </ListItem>
      <ListItem button onClick ={ () => history.push("/mysubscription")}>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary={t("my_subscription")} />
      </ListItem>
      <Divider textAlign="right" sx ={{ color:"white", fontSize:7}}>{t("site_settings")}</Divider>
      <ListItem button>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={t("settings")} />
      </ListItem>
      <ListItem button key="help_support">
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary={t("help_support")} />
      </ListItem>
      <Divider textAlign="right" sx ={{ color:"white", fontSize:7}}>{t("close_session")}</Divider>
      <ListItem key="logout" button onClick = {() => Logout(history)}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText  primary={t("logout")}  />
      </ListItem>
      </HandleRightToLeftDirection>
    </div>
  )};
export default function DropDownMenu() {
    let TxtDir = RtlOutput()
    const styles = {
        position: 'absolute',
        top: 45,
        left: TxtDir==="ltr" ? "" : 15,
        right:TxtDir==="ltr" ? 15 : "",
        zIndex: 1,
        border: '1px solid',
        bgcolor: "#b0bec5",
        width:300,
        borderRadius:2,
      }; 
   return ( 
    <Box sx={styles} >
                    
        <AccountMenu/> 
            
    </Box>
    );
}