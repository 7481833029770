import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Layout from "../components/accountlayout"
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";
import axios from "axios";
import Loader from "../components/loaders/loader";
import logo from "./logo.png";
import moment from "moment"
import {MyTimer} from "./timer"

const data =[
    {
        membership:"free_trial",
        price:"membership_price_free",
        features:["four_seven_support", "trial_period"],
        color:"#26a69a",
        duration:7,
    },
    {
        membership:"gold_member",
        price:"membership_price_gold",
        features:["four_seven_support","member_period","number_students_gold"],
        color:"#ff7043",
        duration:366
    },
    {
        membership:"diamond_member",
        price:"membership_price_diamond",
        features:["four_seven_support", "member_period", "number_students_diamond"],
        color:"#303f9f",
        duration:366
    }
]
export default function MySubscription() {
    const { t } = useTranslation();
    const [Isloading, setIsloading] = useState(false);
    const history = useHistory();
    const [teacher, setTeacher] = useState("")
    const [plan, setPlan] = useState("")
    const [idleTimer, setidleTimer]=useState(null)
    const [time, setTime] = useState(new Date())
    const [disableB, setDisableB] = useState(false)
    const config = {headers:{
    bearer:localStorage.getItem("bearer")}};
    
    
    const DFasSeconds =(now, then, duration)=>{
    let conversion = 24*60*60
    const difference = moment.duration(moment(now).diff(moment(then))).asSeconds()
    const timeleft =now.getSeconds() + duration*conversion - difference
    return timeleft};  
    
    useEffect(async()=>{
    let now = moment(time)
    setIsloading(true)
    try{
      const response = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher", config) 
      setTeacher(response.data)
      if (response.data.membership ==="free")
      {setPlan(data[0]);time.setSeconds(DFasSeconds(time, response.data.membership_date,data[0].duration))}
      else if (response.data.membership ==="gold")
      {setPlan(data[1]);time.setSeconds(DFasSeconds(time, response.data.membership_date,data[1].duration))}
      else if (response.data.membership ==="diamond")
      {setPlan(data[2]);time.setSeconds(DFasSeconds(time, response.data.membership_date,data[2].duration))}
    }catch(err){}
    setIsloading(false)
    },[])
return (    
    <Layout>  
        <Grid container>
                <Grid container sx={{ ml:3,mr:3, height:"100%"}} >
                {Isloading?"":(
                            <Grid item xs={12} 
                               style={{display:"flex",
                                       justifyContent:"center",
                                       border:"3px solid #cfd8dc",
                                       borderRadius:"10px",
                                       backgroundColor:"#F5F9FB",
                                       marginLeft:"3.7%",
                                       marginRight:"3.7%",
                                       marginTop:"3%",
                                       height:"80%"}}
                            >
                                <div style={{justifyContent:"center", textAlign:"center"}}>
                                    <h2 style={{color:plan.color, justifyContent:"center"}}> {t(plan.membership)}</h2>
                                    <br/>
                                    <div style={{direction:"ltr"}}>
                                            <MyTimer expiryTimestamp={time} />
                                    </div>
                                    <br/>
                                    <Button variant="contained"
                                            sx={{mb:5}} 
                                            onClick={()=> {history.push("/subscribe"); setDisableB(true)}}
                                            disable ={disableB}> 
                                        {t("upgrade_extend")}
                                    </Button>
                                </div>
                            </Grid>
                )}    
                </Grid>
        </Grid>
    </Layout>
)}

