import React, {useState, useEffect} from 'react';
import "./productpopup.css"
import Button from '@mui/material/Button';
import Box  from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CirclePicker  } from 'react-color';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {PaymentConditionalSchema} from "../Validations/paymentvalidation"
import {useFormik} from "formik";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import axios from "axios";
import moment from "moment-timezone"
import { fabClasses } from '@mui/material';
import {useTranslation} from "react-i18next";
import HandleRightToLeftDirection from "../components/rtl/rtl";
import {RtlOutput} from "../components/rtl/rtldropdown";


function PaymentPopup(props) {
    const {t}= useTranslation();
    const [APIresponse ,setAPIresponse] = useState("");
    const [APIerror, setAPIERROR] = useState("");
    const [students, setStudents] = useState("");
    const [studentid, setStudentid] = useState("");
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    let TxtDir = RtlOutput()
    const handlePaymentChange = (event) => {
        props.setPaying(event.target.value)}
    const config = {headers:{
        bearer:localStorage.getItem("bearer"),
    }}
    const MenuProps = {
                        PaperProps: {
                            style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                            },
                        },
                    };      
    const formik = useFormik({
        initialValues:{
          name:props.paymentMod.student_id?props.paymentMod.student_id:"" ,
          payed:props.paymentMod.payment?props.paymentMod.payment:"",
          tobepayed:props.paymentMod.tobepayed?props.paymentMod.tobepayed:"",
          paymentdate:props.paymentMod.payment_date ? moment(props.paymentMod.payment_date).tz("Africa/Algiers").format('YYYY-MM-D') : moment(new Date()).tz("Africa/Algiers").format('YYYY-MM-D'),
          tobemod:props.paymenttomod[0] ? props.paymenttomod[0] : "",
          },
        onSubmit: async( values) =>{
          axios.post(`https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/payment/${values.name}`, values ,config).then(
                res=>{
                    setAPIERROR("")
                     props.setreload(props.reload+1)
                     if (res.data.message==="Payment submitted"){
                       setAPIresponse(t("payment_done"))}}
              ).catch(
                err=>{setAPIresponse();if (err.response.data.message==="Please update student classe"){
                    setAPIERROR(t("update_student_classe"))
                }else if (err.response.data.message==="Student not found") {t("student_not_found")}})
        },
        validationSchema:PaymentConditionalSchema(props.paying),
        enableReinitialize:true,
    });
    useEffect (  () => {
        setAPIresponse("")
        setAPIERROR("")
    },[props.trigger]);
    return (props.trigger) ? ( 
        <HandleRightToLeftDirection>
        <div className="popup">
               {localStorage.getItem("drawer") ===true ?"":""}
               <div className={ TxtDir ==="rtl"?
               (localStorage.getItem("drawer") ==="true" ?"popup-inner-rtl-drawer":"popup-inner-rtl"):
               (localStorage.getItem("drawer") ==="true" ?"popup-inner-drawer":"popup-inner" )} >
                            <Box  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: "center",
                                    bgcolor: 'background.paper',
                                    borderRadius:5,
                                    }}>
                                <Grid  container  columns={{ sm:1, xs:1}}>
                                    <Grid item sm = {1} xs ={1}  
                                        sx={{ border: 1,borderRadius: 16,mb:2,
                                            justifyContent: 'center',
                                            alignItems: "center" }}>       
                                        <Typography sx={{ mr:1,
                                            ml:2,mb:1,mt:1,
                                            justifyContent: 'center',
                                            alignItems: "center",
                                            }}
                                            fontSize={10}>
                                                {t("update_student_account")}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm = {1} xs ={1}>
                                        <Box component="form"   onSubmit={formik.handleSubmit} sx={{ mt: 0.5 }}>
                                            {!APIerror? "":<Alert severity="error"> {APIerror}</Alert>}
                                            {!APIresponse? "":<Alert severity="success"> {APIresponse}</Alert>}
                                            <TextField
                                                  fullWidth
                                                  select
                                                  SelectProps={{ MenuProps:  MenuProps }}
                                                  id="name"
                                                  name="name"
                                                  size="small"
                                                  sx={{ mt: 0.5, mb:0.5 }}
                                                  label= {t("student")}
                                                  value = {formik.values.name}
                                                  onChange = { formik.handleChange  }
                                                  error = {formik.touched.name && !!formik.errors.name}
                                                  helperText = {t(formik.touched.name && formik.errors.name)}
                                                  >
                                                  {props.students.map((student) => (
                                                            <MenuItem key={student.lastname + " " +student.firstname}
                                                                      value={student.id}>
                                                                {student.lastname + " " + student.firstname}
                                                            </MenuItem> 
                                                       ))
                                                  }
                                             </TextField>
                                             <LocalizationProvider  dateAdapter={AdapterDateFns} >
                                                <Stack spacing={3}  >
                                                    <DatePicker
                                                        label={t("payment_date")}
                                                        value = {formik.values.paymentdate}
                                                        onChange={(newValue) => {
                                                        formik.setFieldValue("paymentdate",moment(newValue).tz("Africa/Algiers").format('YYYY-MM-D'));
                                                        }}
                                                        // Update this field if website used in a different country
                                                        renderInput={(params) => <TextField sx={{mt:0.5,mb:0.5, }} size="small" {...params} />}
                                                        error = {formik.touched.paymentdate && !!formik.errors.paymentdate}
                                                        helperText = {t(formik.touched.paymentdate && formik.errors.paymentdate)}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                            <FormControl fullWidth>
                                                <InputLabel id="payment-control">{t("debt_payment")}</InputLabel>
                                                <Select
                                                    id="payment-select"
                                                    value={props.paying}
                                                    onChange={handlePaymentChange}
                                                >
                                                    <MenuItem value={"payed"}>{t("amount_payed")}</MenuItem>
                                                    <MenuItem value={"tobepayed"}>{t("amount_tobepayed")}</MenuItem>
                                                </Select>
                                            </FormControl>

                                             <TextField
                                                  margin="normal"
                                                  disabled = {props.paying=="payed"?false:true}
                                                  fullWidth
                                                  size="small"
                                                  sx={{ mt: 0.5 }}
                                                  name="payed"
                                                  label={t("amount_payed")}
                                                  id="payed"
                                                  autoComplete="payed"
                                                  value = {props.paying=="payed"?formik.values.payed:formik.values.payed=""}
                                                  onChange = { formik.handleChange }
                                                  error = {formik.touched.payed && !!formik.errors.payed}
                                                  helperText = {t(formik.touched.payed && formik.errors.payed)}
                                             />
                                              <TextField
                                                  margin="normal"
                                                  disabled = {props.paying=="tobepayed"?false:true}
                                                  fullWidth
                                                  size="small"
                                                  sx={{ mt: 0.5 }}
                                                  name="tobepayed"
                                                  label={t("amount_tobepayed")}
                                                  id="tobepayed"
                                                  autoComplete="tobepayed"
                                                  value = {props.paying=="tobepayed"?formik.values.tobepayed:formik.values.tobepayed=""}
                                                  onChange = { formik.handleChange }
                                                  error = {formik.touched.tobepayed && !!formik.errors.tobepayed}
                                                  helperText = {t(formik.touched.tobepayed && formik.errors.tobepayed)}
                                             />
                                             <Button
                                                  type="submit"
                                                  fullWidth
                                                  size="small"
                                                  
                                                  sx={{ mt: 0.5, mb: 0.5, bgcolor:"#81c784", color:"white",":hover":{bgcolor:"#43a047"} }}
                                             >
                                                  {props.paymenttomod ? t("update")  : t("add")}
                                             </Button>
                                            <Button
                                                  
                                                  sx={{bgcolor:"#ef9a9a", mt:0.5,color:"white",":hover":{bgcolor:"#ef5350"} }}
                                                  fullWidth
                                                  size="small"
                                                  onClick ={() => props.setTrigger(false)}
                                             >
                                                 {t("close")} 
                                             </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        
                        {props.children}
                </div>
        </div>
        </HandleRightToLeftDirection>

     ):""  ;
}

export default PaymentPopup;