import React, {useState, useEffect} from 'react';
import Link from '@mui/material/Link';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import axios from "axios";
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Studentpopup from "../popups/studentpopup";
import LotPopUP from "../popups/lotpopup";
import Layout from "../components/accountlayout"
import CollapsibleTable from "../components/tables/studentstable"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Qrpopup from "../popups/qrpopup";
import PaymentPopup from "../popups/paymentpopup";
import Loader from "../components/loaders/tableloader";
import {useTranslation} from "react-i18next";

export default function ProductsDisplay() {
    const {t} = useTranslation();
    const [students, setStudents] = useState([]);
    const [IsLoading, setIsLoading] = useState(false);
    const [ButtonStudentPopup, SetButtonPpopup] = useState(false);
    const [PaymantButton, setPaymentButton] = useState(false);
    const [ButtonQRPopup, SetButtonQRpopup] = useState(false);
    const [SelectedQR, SetselectedQr] = useState("")
    const [QRdownload, SetQRdownload] = React.useState("");
    const [ModButton, SetModButtom]=useState(false);
    const [studentTobeModfied, SetTobeMod] =useState("");
    const [studentMod, setStudentMod] = useState("");
    const [reload, setreload] = useState(1)
    const [payments, setPayments] = useState("")
    useEffect ( async () => {
            setIsLoading(true);
            const config = {headers:{
                    bearer:localStorage.getItem("bearer"),
                }}  
            try {
                const paymentsresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/payment/sum", config );
                const studentresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/students", config );
                if (studentresponse.data.message==="Teacher has no students"){setStudents("")}
                else{setStudents(studentresponse.data); setPayments(paymentsresponse.data)}
            } catch(err){}
            setIsLoading(false);
            
    },[reload]);
    useEffect ( async () => {
        if (studentTobeModfied){
            setStudentMod(students.filter(student => student.id === parseFloat(studentTobeModfied))[0])
        }
    },[studentTobeModfied]);

   return (
    
    <Layout >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            
                                <Title>{t("my_students")}</Title>
                                <Box sx={{ flexGrow: 1 }}>
                                    <AppBar position="static" >
                                        <Toolbar sx={{bgcolor:"white"}} >
                                            
                                            <Grid  container  columns={{ sm:12, xs:1}}>
                                                <Grid item sm={4} xs={1} sx={{mt:1,mb:1,mr:1,ml:1}}>
                                                    
                                                        <Button fontSize={1} 
                                                                fullWidth
                                                                variant="contained" 
                                                                sx ={{bgcolor:"#e0e0e0",
                                                                      color:"black", mt:1,mb:1,mr:1,ml:1}} 
                                                                onClick={()=> SetButtonPpopup(true)}>
                                                            <Typography fontSize={12} sx ={{fontWeight:"Bold"}}>
                                                            {!ModButton? t("add_student"):t("modify")} 
                                                            </Typography> 
                                                        </Button>
                                                    
                                                </Grid>
                                            </Grid>
                                           
                                        </Toolbar>
                                    </AppBar>    
                                </Box>
                                
                                {IsLoading ? <Loader/> :
                                [!students ? t("empty_database"): <>  <CollapsibleTable products ={students}
                                                                                    SetselectedQr = {SetselectedQr}
                                                                                    setTrigger={SetButtonQRpopup}
                                                                                    SetQRdownload={SetQRdownload}
                                                                                    setMod ={SetModButtom}
                                                                                    SetTobeMod = {SetTobeMod}
                                                                                    SetButtonPpopup={SetButtonPpopup}
                                                                                    setStudentMod={setStudentMod}
                                                                                    payments={payments} />
                                                                    </>]}
                            </Paper>
                        </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
                
                
                {/* Student, Payment & QR Popup */}
                     <Studentpopup trigger ={ButtonStudentPopup}
                                   setTrigger={SetButtonPpopup}
                                   ModButton={ModButton}
                                   studentMod={studentMod}
                                   studentTobeModfied={studentTobeModfied}
                                   setreload={setreload}
                                   reload={reload}/>
                     <Qrpopup trigger ={ButtonQRPopup}
                              setTrigger={SetButtonQRpopup}
                              QRdownload={QRdownload}/>
                    
                 {/* Student Popup */}   

        </Container>   
    </Layout>
  );
}
