import React, {useState, useEffect, useRef} from 'react';
import Link from '@mui/material/Link';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import TextField from "@material-ui/core/TextField"
import Paper from '@mui/material/Paper';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Layout from "../components/accountlayout"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useFormik} from "formik";
import {ModifyIntoSchema} from "../Validations/modifyinfovalidation";
import {ModifyPasswordSchema} from "../Validations/modifypasswordvalidation";
import {useTranslation} from "react-i18next";
import HandleRightToLeftDirection from "../components/rtl/rtl";
import Loader from "../components/loaders/tableloader"

export default function ModifyAccount() {
   const {t} = useTranslation();
   const [APIerror, setAPIERROR] = useState("");
   const [APIerrorPassword, setAPIERRORpassword] = useState("");
   const [APIresponse, setAPIresponse] = useState("");
   const [APIresponsepass, setAPIresponsepass] = useState("");
   const [infoResponse, setinfoResponse] = useState("");
   const config = {headers:{
                bearer:localStorage.getItem("bearer"),
                }} 
   useEffect ( async () => {
        try {
            const inforesponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher", config );
            setinfoResponse(inforesponse.data)
            } catch(err){}
     },[APIresponse])
   const formikinfo = useFormik({
    initialValues:{
      lastname:infoResponse.lastname?infoResponse.lastname:"",
      firstname:infoResponse.firstname?infoResponse.firstname:"",
      company:infoResponse.company?infoResponse.company:"",
      phone:infoResponse.phone?infoResponse.phone:"",
      email:infoResponse.email?infoResponse.email:""
    },
    onSubmit: async(values) =>{
      axios.put("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/info", values, config).then(
            res=>{
                setAPIresponse("Vos données ont été modifier avec success")
            }
          ).catch(
            err=>{
              if (err.response.data.message ==="email already in use"){
              setAPIERROR("L'adresse email deja utilisé !")}
              else if(err.response.data.message ==="Phone already in use")
              {setAPIERROR("Numero de téléphone déja utilisé !")}
              else if (err.response.data.message ==="Company name already in use")
              {setAPIERROR("Nom de companie déja utilisé !")};
              })
    },
    validationSchema:ModifyIntoSchema,
    enableReinitialize:true,
});  
const formikpassword = useFormik({
    initialValues:{
      oldpassword:"",
      password:"",
      cpassword:"",
    },
    onSubmit: async(values) =>{
      axios.put("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/password", values, config).then(
            res=>{        
                setAPIresponsepass("Mot de passe changer avec success")
                setAPIERRORpassword("")   
            }
          ).catch(
            err=>{  
              if (err.response.data.message ==="Please verify your password!"){
              setAPIERRORpassword("Veuillez vérifier votre mot de passe"); setAPIresponsepass("")};
              })
    },
    validationSchema:ModifyPasswordSchema,
    enableReinitialize:true,
});
return (
    <Layout >
        <HandleRightToLeftDirection>
            
                        {!infoResponse? <Loader/> :
                <>        
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                        <Grid item xs={12}>
                                    <Paper sx={{ p: 2,
                                                display: 'flex',
                                                flexDirection: 'column' }}>
                                        <Title>{t("my_informations")}</Title>
                                            {!APIerror ? ""  : <Alert severity="error"> {APIerror}</Alert>} 
                                            {!APIresponse? "" : <Alert severity="success"> {APIresponse}</Alert>}
                                        <Box component="form"
                                            noValidate
                                            onSubmit={formikinfo.handleSubmit}
                                            sx={{ mt: 1, width:"80%",
                                                alignSelf:"center",
                                                justifySelf:"center"}}>
                                            <Grid container >
                                                <Grid item  xs={12} sm={12} md={5.5} lg={5.5} sx={{mr:3}}>
                                                    <TextField
                                                        variant="filled"
                                                        margin="normal"
                                                        size="small"
                                                        fullWidth
                                                        id="lastname"
                                                        label={t("lastname")}
                                                        name="lastname"
                                                        autoComplete="Nom"
                                                        autoFocus
                                                        value = {formikinfo.values.lastname}
                                                        onChange = { formikinfo.handleChange  }
                                                        error = {formikinfo.touched.lastname && !!formikinfo.errors.lastname}
                                                        helperText = {t(formikinfo.touched.lastname && formikinfo.errors.lastname)}
                                                    />
                                                    <TextField
                                                        variant="filled"
                                                        margin="normal"
                                                        size="small"
                                                        fullWidth
                                                        id="firstname"
                                                        label={t("firstname")}
                                                        name="firstname"
                                                        autoComplete="firstname"
                                                        autoFocus
                                                        onChange = { formikinfo.handleChange  }
                                                        value = {formikinfo.values.firstname}
                                                        error = {formikinfo.touched.firstname && !!formikinfo.errors.firstname}
                                                        helperText = {t(formikinfo.touched.firstname && formikinfo.errors.firstname)}

                                                    />
                                                    <TextField
                                                        variant="filled"
                                                        margin="normal"
                                                        size="small"
                                                        fullWidth
                                                        id="company"
                                                        label={t("company")}
                                                        name="company"
                                                        autoComplete="company"
                                                        autoFocus
                                                        onChange = { formikinfo.handleChange  }
                                                        value = {formikinfo.values.company}
                                                        error = {formikinfo.touched.company && !!formikinfo.errors.company}
                                                        helperText = {t(formikinfo.touched.company && formikinfo.errors.company)}
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={5.5} lg={5.5} sx={{mr:3}}>
                                                    <TextField
                                                        variant="filled"
                                                        margin="normal"
                                                        size="small"
                                                        fullWidth
                                                        id="phone"
                                                        label={t("tel")}
                                                        name="phone"
                                                        autoComplete="tel"
                                                        autoFocus
                                                        value = {formikinfo.values.phone}
                                                        onChange = { formikinfo.handleChange  }
                                                        error = {formikinfo.touched.phone && !!formikinfo.errors.phone}
                                                        helperText = {t(formikinfo.touched.phone && formikinfo.errors.phone)}

                                                    />
                                                    <TextField
                                                        variant="filled"
                                                        margin="normal"
                                                        size="small"
                                                        fullWidth
                                                        id="email"
                                                        label={t("email")}
                                                        name="email"
                                                        autoComplete="email"
                                                        autoFocus
                                                        onChange = { formikinfo.handleChange  }
                                                        value = {formikinfo.values.email}
                                                        error = {formikinfo.touched.email && !!formikinfo.errors.email}
                                                        helperText = {t(formikinfo.touched.email && formikinfo.errors.email)}

                                                    />
                                                
                                                    <Button
                                                        type="submit"
                                                        size="large"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{   mt: 2,
                                                                bgcolor:"#81c784",
                                                                color:"white",
                                                                ":hover":{bgcolor:"#43a047"}}}
                                                    >
                                                        {t("modify_informations")}
                                                    </Button>
                                                </Grid> 
                                            </Grid> 
                                        </Box>
                                            
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Paper sx={{ p: 2,
                                                        display: 'flex',
                                                        flexDirection: 'column' }}>
                                                <Title>{t("my_password")}</Title>
                                                    {!APIerrorPassword ? ""  : <Alert severity="error"> {APIerrorPassword}</Alert>} 
                                                    {!APIresponsepass? "" : <Alert severity="success"> {APIresponsepass}</Alert>}
                                                <Box component="form"
                                                    noValidate
                                                    onSubmit={formikpassword.handleSubmit}
                                                    sx={{ mt: 1, width:"80%",
                                                        alignSelf:"center",
                                                        justifySelf:"center"}}>
                                                    <Grid container >
                                                        <Grid item xs={12} sm={12} md={5.5} lg={5.5} sx={{mr:3}}>
                                                        <TextField
                                                                variant="filled"
                                                                margin="normal"
                                                                size="small"
                                                                fullWidth
                                                                name="oldpassword"
                                                                label={t("old_password")}
                                                                type="password"
                                                                id="oldpassword"
                                                                autoComplete="current-oldpassword"
                                                                onChange = { formikpassword.handleChange  }
                                                                value = {formikpassword.values.oldpassword}
                                                                error = {formikpassword.touched.oldpassword && !!formikpassword.errors.oldpassword}
                                                                helperText = {t(formikpassword.touched.oldpassword && formikpassword.errors.oldpassword)}

                                                            />
                                                            <TextField
                                                                    variant="filled"
                                                                    margin="normal"
                                                                    size="small"
                                                                    fullWidth
                                                                    name="password"
                                                                    label={t("new_password")}
                                                                    type="password"
                                                                    id="password"
                                                                    autoComplete="current-password"
                                                                    onChange = { formikpassword.handleChange  }
                                                                    value = {formikpassword.values.password}
                                                                    error = {formikpassword.touched.password && !!formikpassword.errors.password}
                                                                    helperText = {t(formikpassword.touched.password && formikpassword.errors.password)}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={5.5} lg={5.5} sx={{mr:3}}>
                                                            <TextField
                                                                    variant="filled"
                                                                    margin="normal"
                                                                    size="small"
                                                                    fullWidth
                                                                    name="cpassword"
                                                                    label={t("confirm_password_input")}
                                                                    type="password"
                                                                    id="cpassword"
                                                                    autoComplete="current-password"
                                                                    onChange = { formikpassword.handleChange  }
                                                                    value = {formikpassword.values.cpassword}
                                                                    error = {formikpassword.touched.cpassword && !!formikpassword.errors.cpassword}
                                                                    helperText = {t(formikpassword.touched.cpassword && formikpassword.errors.cpassword)}

                                                                />
                                                            <Button
                                                                type="submit"
                                                                size="large"
                                                                fullWidth
                                                                variant="contained"
                                                                sx={{   mt: 2,
                                                                        bgcolor:"#81c784",
                                                                        color:"white",
                                                                        ":hover":{bgcolor:"#43a047"}}}
                                                            >
                                                                {t("modify_password")}
                                                            </Button>
                                                        </Grid> 
                                                    </Grid> 
                                                </Box>
                                                    
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Copyright sx={{ pt: 4 }} />
                            
                        </Container>
                    </>}
                        
            
    </HandleRightToLeftDirection>
    </Layout>
  );
}
