import React, {useState, useEffect} from 'react';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Layout from "../components/accountlayout"
import AddBoxIcon from '@mui/icons-material/AddBox';
import CollapsibleTable from "../components/tables/studentstable"
import Divider from '@mui/material/Divider';
import { styled } from '@mui/system';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { Typography } from '@mui/material';
import {useHistory} from "react-router-dom"
import { useTranslation } from "react-i18next";


const IconWrapper = styled(Paper)({
    textAlign: 'center',
    lineHeight: '60px',
    backgroundColor: '#cfd8dc',
    width:"150px",
    "borderRadius":8,
    '&:hover': {backgroundColor: "#b0bec5"}
});

  const DividerStyled = styled(Divider)({
    width:"80%",
 });
const StyledListItem = styled(ListItem)({
    "marginTop": "15px",
    "marginBottom": "15px",
    width:"80%",
  });


export default function ApplicationDisplay() {
    const [isLoading, setIsLoading] = useState(false);
    let history = useHistory();
    const [screensize, setScreensize] = useState("Large")
    const {t}=useTranslation();
    // get windown width on lunch
  useEffect( () =>{
    if (window.screen.width < 600 && screensize ==="Large"){setScreensize("Mobile")}
      else if (window.screen.width >= 600 && screensize === "Mobile") {setScreensize("Large")}
    }, [])
  // get windown width on lunch
  // get windown width on resize
    useEffect( () =>{
        function handleResize() {
          if (window.innerWidth < 600 && screensize ==="Large"){setScreensize("Mobile")}
          else if (window.innerWidth >= 600 && screensize==="Mobile") {setScreensize("Large")}
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [screensize])
return (
    <Layout >
                <Grid container spacing={3}>
                        <Grid item xs={12}>
                                <Typography variant="caption" sx={{pl:2, pr:2}} > {t("education")}</Typography>
                                <Divider style={{width:"80%"}}/>
                                { !isLoading
                                    ? (
                                        
                                        <> 
                                          <List>
                                            <StyledListItem >
                                                <Grid  container 
                                                        rowSpacing={{ xs:3 }} 
                                                        columns={{ sm:2 }} 
                                                        columnSpacing={{xs:4 }} 
                                                        sx={{textAlign:"center", pl: 3, pr: 3}}
                                                        justifyContent={screensize==="Large"? "flex-start": "center" }
                                                        display={screensize ==="Large"? "": "flex" }
                                                        >
                                                    <Grid item  
                                                          onClick={()=> history.push("/students")}>
                                                        <IconWrapper>
                                                                <AddBoxIcon   sx={{ fontSize: 70, mt:2, mb:-2 }}/>
                                                                <Typography  variant="body2" >
                                                                    {t("add_student")}
                                                                </Typography>
                                                        </IconWrapper>
                                                    </Grid>
                                                    <Grid item onClick ={()=> history.push("/calendar")}>
                                                        <IconWrapper>
                                                            <CalendarTodayIcon sx={{ fontSize: 70, mt:2, mb:-2 }}/>
                                                            <Typography  variant="body2"> {t("my_calendar")}
                                                                </Typography>
                                                        </IconWrapper>
                                                    </Grid>
                                                    <Grid item onClick={()=> history.push("/classes")}>
                                                        <IconWrapper>
                                                            <ConnectWithoutContactIcon sx={{ fontSize: 70, mt:2, mb:-2 }}/>
                                                            <Typography  variant="body2" > {t("classes")}
                                                                </Typography>
                                                        </IconWrapper>
                                                    </Grid>
                                                    <Grid item onClick={()=> history.push("/presence")}>
                                                        <IconWrapper>
                                                            <AccessibilityNewIcon sx={{ fontSize: 70, mt:2, mb:-2 }}/>
                                                            <Typography  variant="body2"> {t("presence_qr")}
                                                                </Typography>
                                                        </IconWrapper>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconWrapper>
                                                            <ArticleIcon sx={{ fontSize: 70, mt:2, mb:-2 }}/>
                                                            <Typography  variant="body2">Mon programme
                                                                </Typography>
                                                        </IconWrapper>
                                                    </Grid>
                                                </Grid>      
                                            </StyledListItem>
                                            <Typography variant="caption" sx={{pl:2, pr:2}} >{t("extras")}</Typography>                                 
                                            <DividerStyled/>
                                                <StyledListItem>
                                                    <Grid container 
                                                        rowSpacing={{ xs:3 }} 
                                                        columns={{ sm:2 }} 
                                                        columnSpacing={{xs:4 }}
                                                        sx={{textAlign:"center", pl: 3, pr: 3}}
                                                        justifyContent={screensize==="Large"? "flex-start": "center" }
                                                            display={screensize ==="Large"? "": "flex" }
                                                        >
                                                            <Grid item >
                                                                <IconWrapper>
                                                                        <EditIcon sx={{ fontSize: 70, mt:2, mb:-2 }}/>
                                                                        <Typography  variant="body2"> Reschedule                                                               </Typography>
                                                                </IconWrapper>
                                                            </Grid>
                                                            <Grid item >
                                                                <IconWrapper>
                                                                    <KeyboardReturnIcon sx={{ fontSize: 70, mt:2, mb:-2 }}/>
                                                                    <Typography  variant="body2">Année précedente
                                                                        </Typography>
                                                                </IconWrapper>
                                                            </Grid>
                                                            <Grid item  onClick={()=> history.push("/payments")}>
                                                                <IconWrapper>
                                                                    <BorderColorIcon sx={{ fontSize: 70, mt:2, mb:-2 }}/>
                                                                    <Typography  variant="body2"> {t("payments")}
                                                                        </Typography>
                                                                </IconWrapper>
                                                            </Grid>
                                                            <Grid item  onClick={()=> history.push("/presences")}>
                                                                <IconWrapper>
                                                                    <FolderSharedIcon sx={{ fontSize: 70, mt:2, mb:-2 }}/>
                                                                    <Typography  variant="body2">{t("attendances")}
                                                                        </Typography>
                                                                </IconWrapper>
                                                            </Grid>
                                                        
                                                    </Grid> 
                                                </StyledListItem>
                                          </List>
                                        </>
                                    )
                                    : <div>Loading...</div>
                                }
                            
                        </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
    </Layout>
  );
}
