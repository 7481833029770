import react, {useState} from 'react'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import IconButton from '@mui/material/IconButton';
import { blueGrey } from '@mui/material/colors';
import ClickAwayListener from '@mui/material/ClickAwayListener';


export default function LangDropDown(props) {
    const [open, setOpen] = useState(false);
    const handleclickAway =() =>{
        setOpen(false);
    }
    return (

        <>  
            <ClickAwayListener onClickAway ={handleclickAway}>
                <IconButton color="inherit" onClick ={() => setOpen(!open)}>
                        
                        { props.icon }
                        { open && props.children }
        
                </IconButton>
            </ClickAwayListener>
        </>
            
  
        );
}
;
