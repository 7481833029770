import React, {useState, useEffect, useRef} from 'react';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Layout from "../components/accountlayout"
import Typography from '@mui/material/Typography';
import {Inject, ScheduleComponent,
       Day,Week, DragAndDrop, Resize,
       WorkWeek, Month, ViewDirective,ViewsDirective,
       Agenda, EventSettingsModel, ResourcesDirective, ResourceDirective} from "@syncfusion/ej2-react-schedule";
import schedulercolors from "./schedulercolors.json"
import { extend } from '@syncfusion/ej2-base';
import Loader from "../components/loaders/tableloader";
import {useTranslation} from "react-i18next";

export default function Scheduler() {
    const {t} = useTranslation();
    const [APIerror, setAPIERROR] = useState("");
    const [APIresponse, setAPIresponse] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [schedulerdata, setschedulerdata] = useState({});
    const [constructorHasRun, setConstructorHasRun] = useState(false);
    const [ModifiableTeacherData, setModifiableTeacherData] = useState("");
    const [updating, setUpdating] = useState(false);
    useEffect ( async () => {
        setIsLoading(true);
        const config = {headers:{
                bearer:localStorage.getItem("bearer"),}}  
        try {
            const calendarresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/calendar", config );
            setschedulerdata({"dataSource":calendarresponse.data});
        } catch(err){console.log(err.response);setschedulerdata({"dataSource":[]})}
        setIsLoading(false);
    },[]); 
    const UploadCalendar = async () =>{
        setUpdating(true)
        const config = {headers:{
            bearer:localStorage.getItem("bearer"),}}
            try {
                const uploadresponse = await axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/calendar",
                                                 schedulerdata.dataSource,
                                                 config  );
                if (uploadresponse.data.message==="Calendar updated successfuly"){
                    setAPIresponse(t("calendar_updated"));
                    setUpdating(false)
                }else{; setAPIresponse("")}
                
            } catch(err){}}
    const TeacherData : EventSettingsModel = schedulerdata
    const Builtconstructor = () => {
        if (constructorHasRun) return;
        setModifiableTeacherData(extend([],schedulerdata, null, true))  
        setConstructorHasRun(true);
      };
    const ColorDataSrouce : Object[]= schedulercolors;
    
return (
    <Layout >
        
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    {isLoading ? <Loader/> : (
                        <>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                }}
                                >
                                    <Title>{t("my_calendar")}</Title>
                                            {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>} 
                                            {APIresponse.length === 0? "" : <Alert severity="success"> {APIresponse}</Alert>}
                                            {updating ? <Typography>En cours...</Typography> : <Button variant="contained"
                                                                                                sx ={{bgcolor:"#90a4ae", color:"white", width:"100%"}}
                                                                                                onClick={UploadCalendar} >
                                                                                                     {t("update_calendar")}
                                                                                                </Button>}
                                        <ScheduleComponent currentView ="Month"
                                        eventSettings = {schedulerdata}
                                        timezone='Africa/Algiers'
                                        >
                                            <Inject services ={[Day,
                                                                Week,
                                                                Month, 
                                                                Agenda,
                                                                DragAndDrop,
                                                                Resize]} />
                                            <ViewsDirective>
                                                <ViewDirective option="day"/>
                                                <ViewDirective option="month"/>
                                                <ViewDirective option="week"/>
                                                <ViewDirective option="agenda"/>
                                            </ViewsDirective>
                                            <ResourcesDirective>
                                                <ResourceDirective 
                                                field= "ResourceID"
                                                title= "Color"
                                                idField ="Id"
                                                textField="Name"
                                                name="Colors"
                                                colorField="Color"
                                                dataSource={ColorDataSrouce}> 
                                                    
                                                </ResourceDirective>
                                            </ResourcesDirective>
                                        </ScheduleComponent>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    
                </Grid>
                <Copyright sx={{ pt: 4 }} />
        </Container>
    </Layout>
  );
}
