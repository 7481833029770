import React, {useState, useEffect} from 'react';
import Link from '@mui/material/Link';
import Title from '../Dashboard/Title';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import TextField from "@material-ui/core/TextField"
import Paper from '@mui/material/Paper';
import axios from "axios";
import Alert from '@mui/material/Alert';
import Copyright from "../components/copyright"
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Layout from "../components/accountlayout"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ColorPickerpopup  from "../popups/colorpickerpopup";
import {ClassTypesSchema} from "../Validations/classtypesvalidation"
import {useFormik} from "formik";
import CollapsibleTable from "../components/tables/classestable"
import Loader from "../components/loaders/tableloader"
import { useTranslation } from "react-i18next";
import HandleRightToLeftDirection from "../components/rtl/rtl"
import {useHistory} from "react-router-dom"

export default function ClassesDisplay() {
    const history = useHistory();
    const {t} = useTranslation();
    const [APIerror, setAPIERROR] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [ButtoncolorPickerpopup, SetButtoncolorPickerpopup] = useState(false);
    const [ClassColor, SetClassColor]=useState("#ffc400");
    const [crows, SETCROWS] = useState([]);
    const [ModButton, SetModButtom]=useState("none");
    const [addButton, SetaddButtom]=useState("block");
    const [classTobeModfied, SetTobeMod] =useState("");
    const [modID, setModID] = useState("");
    const [modClass, setModClass] = useState("");
    const config = {headers:{
        bearer:localStorage.getItem("bearer"),
    }} 
    const formik = useFormik({
          initialValues:{
          name:modClass[0]? modClass[0].name:"",
          price:modClass[0]? modClass[0].price:"",
          color:ClassColor,
          ClassToMod :"",
        },
        onSubmit: async(values) =>{
          formik.values.color =  ClassColor 
          formik.values.ClassToMod =  classTobeModfied 
          axios.post("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/classes", formik.values ,config).then(
                res=>{
                  window.location.reload(false)
                }
              ).catch(
                err=>{  
                  if (err.response.status ===401){
                  setAPIERROR(err.response.data.message)};
                  })
        },
        validationSchema:ClassTypesSchema,
        enableReinitialize:true,
    });
    // Getting Existing Classes
    useEffect ( async () => {
        setIsLoading(true);
        const config = {headers:{
                bearer:localStorage.getItem("bearer"),
            }}  
        try {
            const cresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/classes", config );
            SETCROWS(cresponse.data);
            ;
        } catch(err){localStorage.clear(); history.push("login")}
        setIsLoading(false);
    },[]);
    useEffect ( async () => {
        setModClass(crows.filter((classe) => classe.id ===modID))
    },[modID]);
return (
    
    <Layout >
    <HandleRightToLeftDirection>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Title>{t("my_classes")}</Title>
                                 {APIerror.length  === 0 ? ""  : <Alert severity="error"> {APIerror}</Alert>} 
                                <Box component="form" onSubmit={formik.handleSubmit} sx={{ flexGrow: 1 }}>
                                        <Toolbar sx={{bgcolor:"white"}} >
                                            <Grid  container  columns={{ sm:12, xs:1}}>
                                                <Grid item sm={3} xs={1} sx={{mr: 1, mb:1 }}>
                                                    <TextField   label = {t("class_name")}
                                                                 variant = "standard"
                                                                 id="name"
                                                                 value = {formik.values.name}
                                                                 onChange = { formik.handleChange  }
                                                                 error = {formik.touched.name && !!formik.errors.name}
                                                                 helperText = {t(formik.touched.name && formik.errors.name) }/>
                                                </Grid>
                                                <Grid item sm={3} xs={1} sx={{ mr: 1, mb:1 }}>
                                                    <TextField label = {t("prix_sceance_da")}
                                                               variant = "standard"
                                                               id="price"
                                                               value = {formik.values.price}
                                                               onChange = { formik.handleChange  }
                                                               error = {formik.touched.price && !! formik.errors.price}
                                                               helperText = {t(formik.touched.price && formik.errors.price)}/>
                                                </Grid>
                                                <Grid item sm={3} xs={1} sx={{ml: 1, mb:1 }}>
                                                        <Button fontSize={1} 
                                                                 size="medium"
                                                                variant="contained" 
                                                                edge="start"
                                                                sx ={{bgcolor:"#e0e0e0", 
                                                                      color:"black", 
                                                                      width:"100%",
                                                                      mt:1.5}}
                                                                type="submit"> 
                                                                <Typography fontSize={13} 
                                                                            sx ={{fontWeight:"Bold"}}
                                                                            >
                                                                    {ModButton =="none"? t("add"):t("modify")}            
                                                                </Typography> 
                                                        </Button>
                                                    
                                                </Grid>
                                            </Grid>
                                        </Toolbar>
                                </Box>
                                { !isLoading
                                    ? (
                                        <>
                                            {crows.length>0? (
                                                 <CollapsibleTable classes ={crows} 
                                                 setColor ={SetClassColor}
                                                 setMod ={SetModButtom}
                                                 setAdd ={SetaddButtom}
                                                 SetTobeMod = {SetTobeMod}
                                                 setModID = {setModID}
                                                   />
                                            ):(
                                                <Typography fontSize={13} 
                                                            sx ={{fontWeight:"Bold", mt:2,ml:2}}
                                                                            >
                                                            {t("no_classe_created")}
                                                </Typography> 
                                            )}
                                        </>
                                    )
                                    : (<>
                                        <Loader/>
                                      </>)
                                }
                            </Paper>
                        </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
            
        </Container>
        </HandleRightToLeftDirection>
    </Layout>
  );
}
