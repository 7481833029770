import React, {useState, useEffect} from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from "axios"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import Loader from "../components/loaders/tableloader"
import moment from "moment"
function preventDefault(event) {
  event.preventDefault();
}

export default function Previousreturns() {
  const {t} = useTranslation();
  const [students, setStudents] = useState([]);
  const [payments, setPayments] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const history = useHistory();
  useEffect ( async () => {
    setIsLoading(true);
    const config = {headers:{
            bearer:localStorage.getItem("bearer"),
        }}  
    try {
        const paymentsresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/payment/sum", config );
        const studentresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/students", config );
        if (studentresponse.data.message==="Teacher has no students"){setStudents("")}
        else{setStudents(studentresponse.data)}
        setPayments(paymentsresponse.data.reduce((totalpayments,m)=>totalpayments+parseFloat(m.payed),0))
    } catch(err){}
    setIsLoading(false);
  },[]);
  
  return (
    <React.Fragment>

              <Grid item xs={12} md={4} lg={3}>
                    <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 240,
                    }}
                    >
                      {IsLoading?<Loader/>:
                      <>
                        <Title>{t("total_students")}</Title>
                            <Typography component="p" variant="h4">
                                {students.length}
                            </Typography>
                        <Title>{t("tota_income")}</Title>
                            <Typography component="p" variant="h4">
                                {payments} {t("da")} 
                            </Typography>
                            <Typography color="text.secondary" sx={{ flex: 1 }}>
                                {moment(new Date()).tz("Africa/Algiers").format('YYYY-MM-D')}
                            </Typography>
                            <div>
                                <Link color="primary"  onClick={()=>history.push("/students")}>
                                {t("students")}
                                </Link>
                                /
                                <Link color="primary"  onClick={()=>history.push("/payments")}>
                                {t("payments")}
                                </Link>
                            </div>
                      </>
                      }
                    </Paper>
              </Grid>
      
    </React.Fragment>
  );
}
