import React, {useEffect, useState} from 'react';
import { useTheme } from '@mui/material/styles';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend} from 'recharts';
import Title from './Title';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from "axios"
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic'
import Loader from "../components/loaders/tableloader"
import { useTranslation } from "react-i18next";

const colors = Array(10).fill(scaleOrdinal(schemeCategory10).range()).flat() 

export default function ClassesPresencesChart() {
  const {t} = useTranslation();
  const [classpresences, setpresences] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [activeIndex, setIndex] = useState(0);
  const [showyaxis, setYAxis] = useState(true);
  const onPieEnter = (_, index) => {
    setIndex(index)
  };
  const theme = useTheme();
  useEffect ( async () => {
    setIsLoading(true);
    const config = {headers:{
            bearer:localStorage.getItem("bearer"),
        }}  
    try {
        const classpresencesresponse = await axios.get("https://tik-tik-teaching-backend-eelrck336q-nw.a.run.app/teacher/scanner/class/sum", config );
        setpresences(classpresencesresponse.data)
    } catch(err){}
    setIsLoading(false);
  },[]);
  useEffect(() => {
    if (window.innerWidth < 600 ) {setYAxis(false)}
    else if (window.innerWidth >= 600 ) {setYAxis(true)}
  }, []); 
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 800 && showyaxis) {setYAxis(false)}
       else if (window.innerWidth >= 800 && showyaxis === false) {setYAxis(true)}}
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [showyaxis]);
 return (
    <React.Fragment>
       <Grid item xs={12} md={12} lg={12}>
            <Paper
                sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height:  !classpresences ? 100 : 350 ,
                }}
            >
                    <Title> {t("total_class_presences")}</Title>
                    {IsLoading ? <Loader/> :
                    !classpresences ? t("empty_database"): 
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                        width={500}
                        height={400}
                        data={classpresences}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month"
                               style={{fontSize:8}}/>
                        {showyaxis && <YAxis style={{fontSize:10}} />}
                        {showyaxis && <Legend verticalAlign="top" 
                                              height={50}
                                              iconSize={8}
                                              wrapperStyle={{fontSize:8}}/>}
                        <Tooltip contentStyle={{fontSize:7}}/>
                            {!classpresences[0]?"": 
                             Object.entries(classpresences[0]).map(([key,presence],index) => 
                                key ==="month"?"":
                                <Area key ={key + presence}
                                      type="monotone" 
                                      dataKey={key} 
                                      stackId="1" 
                                      stroke={colors[index+5]} 
                                      fill={colors[index+5]} />
                            )}
                        </AreaChart>
                    </ResponsiveContainer>
                    }
            </Paper>
        </Grid>
      
    </React.Fragment>
  );
}
